import { toast } from "react-toastify";

//export const homeAdminApi = "http://192.168.100.176:9000/api/";
export const homeAdminApi = "https://administrador.nuevaeconomia.com.bo/api/";

//export const homePagosApi = "http://192.168.100.176:8080/api/";
export const homePagosApi = "https://pagos.nuevaeconomia.com.bo/api/";

export const onCatch = "Ha ocurrido un error, porfavor intenta nuevamente más tarde";
export const onNotFound = "El elemento que solicitaste no se ha encontrado o no se encuentra activo";

export const Catch = (err: any) => {
    toast.error(onCatch)
}

export const formatDate = (date: Date) => {
    return date.getFullYear() + "-" + (date.getMonth()+1).toString().padStart(2, '0') + "-" + date.getDate() + "T" + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
}

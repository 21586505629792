
import axios from "axios";
import { homeAdminApi, homePagosApi } from "./variables";

export let postTo = (to: string, data: FormData, config?:any) => {
    return new Promise((res, rej)=>{
        axios.post(to, data, config).then((r:{data: any})=>{
            res(r.data);
        }).catch((err: any)=>{
            rej(err);
        })
    })
}

export let getTo = (to: string) => {
    return new Promise((res, rej)=>{
        axios.get(to).then((r:{data: any})=>{
            res(r.data);
        }).catch((err: any)=>{
            rej(err);
        })
    })
}
/**
 * 
 * @param values array of elements, key be converted to form key and value than value
 * @param to file to add
 * @returns 
 */
export let madePost = (values: Object, to: string) => {
    let form = new FormData();
    Object.keys(values).map((key: any, index: number)=>{
        form.append(key, (values as any)[key]);
    });

    return post(to, form, {});
}

let post = (to: string, data: FormData, config?:any) => {
    return postTo(homePagosApi + to, data, config);
}
export default post;
/* eslint-disable no-restricted-globals */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Collapse } from "react-collapse";
import { toast } from "react-toastify";
import { Button, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { madePost } from "../common/post";
import { Categories, Course, Product, ResponseGeneral, Winning } from "../common/types";
import { Catch } from "../common/variables";
import { clone } from "../functions/functions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";

import "../theme/styles/components/Card.scss";

export const CardsManager = (props: {
    cards: Course[],
    className?: string
}) => {
    const [index, setIndex] = useState(0);
    const [cardActive, setCardActive] = useState({ value: {} as Course, action: "" });
    const [cards, setCards] = useState([] as Course[]);

    useEffect(() => {
        let f = props.cards.find((e: Course) => { return e.course_code == -1 });
        if (f) {
            setCardActive({ value: f, action: "edit" });
        }

        setCards(props.cards);
    }, [props])

    const onUpdate = (course: Course) => {
        if (typeof course.details == "string") {
            try {
                course.details = JSON.parse(course.details);
            } catch (err) { }
        }
        let cs: Course[] = JSON.parse(JSON.stringify(cards));

        let clean: Course[] = cs.filter((c: Course) => { return c.course_code != 1 });
        let indx: number = clean.findIndex((c: Course) => { return c.course_code == course.course_code });

        if (indx == -1) {
            //Add
            cs.push(course);
        } else {
            //edit
            cs[indx] = course;
        }

        setCards(cs);
        setCardActive({ value: {} as Course, action: "" });
    }

    return <div className={" " + props.className}>
        <Collapse isOpened={cardActive.action.length == 0}>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        to=""
                        className={index == 0 ? "active" : ""}
                        onClick={() => { setIndex(0) }}
                    >
                        Virtuales
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to=""
                        className={index == 1 ? "active" : ""}
                        onClick={() => { setIndex(1) }}
                    >
                        Presenciales
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to=""
                        className={index == 2 ? "active" : ""}
                        onClick={() => { setIndex(2) }}
                    >
                        Talleres
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={index}>
                <TabPane tabId={0}>
                    <div className="cards-manager-component">
                        {
                            cards.filter((c: Course) => { return c.type === "virtual" }).map((card: Course) => {
                                return <Card course={card} title={card.title} description={card.description} image={card.cover_image} price={card.price.toString()}
                                    onCancel={() => {
                                        setCardActive({ value: {} as Course, action: "" })
                                    }}
                                    onEdit={(course: Course) => {
                                        setCardActive({ value: course, action: "edit" })
                                    }}
                                    onUpdate={(c: Course) => {
                                        onUpdate(c);
                                        console.debug(c)
                                    }} />
                            })
                        }

                    </div>

                </TabPane>
                <TabPane tabId={1}>
                    <div className="cards-manager-component">
                        {
                            cards.filter((c: Course) => { return c.type === "face-to-face" }).map((card: Course) => {
                                return <Card course={card} title={card.title} description={card.description} image={card.cover_image} price={card.price.toString()}
                                    onCancel={() => {
                                        setCardActive({ value: {} as Course, action: "" })
                                    }}
                                    onEdit={(course: Course) => {
                                        setCardActive({ value: course, action: "edit" })
                                    }}
                                    onUpdate={(c: Course) => {
                                        onUpdate(c);
                                        console.debug(c)
                                    }} />
                            })
                        }
                    </div>
                </TabPane>
                <TabPane tabId={2}>
                    <div className="cards-manager-component">
                        {
                            cards.filter((c: Course) => { return c.type === "train" }).map((card: Course) => {
                                return <Card course={card} title={card.title} description={card.description} image={card.cover_image} price={card.price.toString()}
                                    onCancel={() => {
                                        setCardActive({ value: {} as Course, action: "" })
                                    }}
                                    onEdit={(course: Course) => {
                                        setCardActive({ value: course, action: "edit" })
                                    }}
                                    onUpdate={(c: Course) => {
                                        onUpdate(c);
                                        console.debug(c)
                                    }} />
                            })
                        }
                    </div>
                </TabPane>
            </TabContent>
        </Collapse>

        <Collapse isOpened={cardActive.action.length > 0}>
            <Card course={cardActive.value} isEdit={true}
                onCancel={() => {
                    setCardActive({ value: {} as Course, action: "" })
                }}
                onUpdate={(c: Course) => {
                    onUpdate(c);
                    console.debug(c)
                }}
            />
        </Collapse >

    </div >
}

export const CardsProductsManager = (props: {
    cards: Product[],
    className?: string
}) => {
    const [index, setIndex] = useState(0);
    const [cardActive, setCardActive] = useState({ value: {} as Product, action: "" });
    const [cards, setCards] = useState([] as Product[]);

    useEffect(() => {
        let f = props.cards.find((e: Product) => { return e.product_code == "-1" });
        if (f) {
            setCardActive({ value: f, action: "edit" });
        }

        setCards(props.cards);
    }, [props])

    const onUpdate = (product: Product) => {
        if (typeof product.winnings == "string") {
            try {
                product.winnings = JSON.parse(product.winnings);
            } catch (err) { }
        }
        if (typeof product.provider == "string") {
            try {
                product.provider = JSON.parse(product.provider);
            } catch (err) { }
        }
        if (typeof product.images == "string") {
            try {
                product.images = JSON.parse(product.images);
            } catch (err) { }
        }

        let cs: Product[] = JSON.parse(JSON.stringify(cards));

        let clean: Product[] = cs.filter((c: Product) => { return c.product_code != "1" });
        let indx: number = clean.findIndex((c: Product) => { return c.product_code == product.product_code });

        if (indx == -1) {
            //Add
            cs.push(product);
        } else {
            //edit
            cs[indx] = product;
        }

        setCards(cs);
        setCardActive({ value: {} as Product, action: "" });
    }

    return <div className={" " + props.className}>
        <Collapse isOpened={cardActive.action.length == 0}>

            <div className="cards-manager-component">
                {
                    cards.map((card: Product) => {
                        try{
                            card.images = JSON.parse(card.images as unknown as string);
                        }catch(err){

                        }
                        return <CardProduct product={card} name={card.name} description={card.description} image={card.images[0]} price={card.price.toString()}
                            onCancel={() => {
                                setCardActive({ value: {} as Product, action: "" })
                            }}
                            onEdit={(product: Product) => {
                                setCardActive({ value: product, action: "edit" })
                            }}
                            onUpdate={(c: Product) => {
                                onUpdate(c);
                                console.debug(c)
                            }} />
                    })
                }

            </div>
        </Collapse>

        <Collapse isOpened={cardActive.action.length > 0}>
            <CardProduct product={cardActive.value} isEdit={true}
                onCancel={() => {
                    setCardActive({ value: {} as Product, action: "" })
                }}
                onUpdate={(c: Product) => {
                    onUpdate(c);
                    console.debug(c)
                }}
            />
        </Collapse >

    </div >
}

export const CardProduct = (props: {
    name?: string,
    description?: string,
    image?: File | string,
    price?: string,
    product: Product,
    className?: string,
    onEdit?: (product: Product) => any,
    onUpdate?: (product: Product) => any,
    isEdit?: boolean,
    onCancel: () => any

}) => {
    const [image, setImage] = useState(props.image as any);
    const [product, setProduct] = useState(props.product);

    const [isEditting, setIsEditting] = useState("");
    const [isTitleSetted, setIsTitleSetted] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [provider, setProvider] = useState({ enterprise: "", comunity: "", others: "", location: "", locationInfo: "", sanitaryCode: "" });
    const [images, setImages] = useState([] as string[])

    const addSkillRef = useRef({} as HTMLInputElement);
    const nameRef = useRef({} as HTMLInputElement);
    const descriptionRef = useRef({} as HTMLInputElement);
    const priceRef = useRef({} as HTMLInputElement);

    const manage = () => {
        if (product.active == 1) {
            let rrr = confirm("Está seguro de deshabilitar este producto? Ya no se mostrará en la página web");

            if (rrr) {
                madePost(
                    {
                        key: "manage-product",
                        state: 0,
                        product_code: product.product_code
                    },
                    "AdminPagos.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        let p = clone(product);
                        p.active = 0;

                        setProduct(p);
                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(Catch)
            }
        } else {
            let rrr = confirm("Está seguro de habilitar este producto? Se volverá a mostrar en la página web")

            if (rrr) {
                madePost(
                    {
                        key: "manage-product",
                        state: 1,
                        product_code: product.product_code
                    },
                    "AdminPagos.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        let p = clone(product);
                        p.active = 1;

                        setProduct(p);
                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(Catch)
            }
        }
    }

    const analizeproduct = () => {
        try {
            if (images.length > 0 &&
                product.description.length > 0 &&
                product.winnings.length > 0 &&
                product.price.toString().length > 0 &&
                product.name.length > 0
            ) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } catch (err) {
            setIsValid(false);
        }

        try {
            if (product.name.length > 0 && product.name != "Producto ...") {
                setIsTitleSetted(true);
            } else {
                setIsTitleSetted(false);
            }
        } catch (err) {
            setIsTitleSetted(false);
        }
    }

    const save = () => {
        madePost(
            {
                key: product.product_code == "-1" ? "create-product" : "edit-product",
                product_code: product.product_code,
                name: product.name,
                description: product.description,
                category: product.category,
                winnings: JSON.stringify(product.winnings),
                images: JSON.stringify(images),
                provider: JSON.stringify(provider),
                price: product.price,
                active: product.active
            },
            "AdminPagos.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("El producto se ha " + (product.product_code == "-1" ? "creado" : "editado") + " correctamente");

                props.onUpdate!(res.VALUE);
            } else {
                toast.error("El producto no se ha podido editar")
            }
        }).catch(Catch)
    }

    const providerChange = (key: string, value: string) => {
        let p = JSON.parse(JSON.stringify(provider));

        p[key] = value;

        setProvider(p);
    }

    useEffect(() => {
        let p: Product = clone(props.product);

        try {
            if (typeof p.winnings == "string") {
                p.winnings = JSON.parse(p.winnings as unknown as any);
            }
            if (typeof p.images == "string") {
                p.images = JSON.parse(p.images as unknown as any);
            }
            if (typeof p.provider == "string") {
                p.provider = JSON.parse(p.provider as unknown as any);

            }
        } catch (err) { }
        setImage(props.image);
        setProduct(p);
        setProvider(p.provider);
        setImages(p.images);
    }, [props])

    useEffect(() => {
        analizeproduct()
    }, [product]);

    if (props.isEdit) {
        return <div className="card-component card-edit">
            {
                isTitleSetted ?
                    <div>
                        <span className="d-block w-100 text-align-start m-0">Imagenes del producto: </span>
                    </div>
                    :
                    <></>
            }

            {
                isTitleSetted ?
                    <div className="edit-area d-flex flex-row align-items-center py-0 images-uploaded-edit">
                        {
                            images ?
                                images.map((img: string) => {
                                    return <div className="image-item">
                                        <img src={img} alt="" />

                                        <i className="bi bi-x" onClick={() => {
                                            let imgsFil = images.filter((i: string) => {
                                                return i != img;
                                            })
                                            setImages(imgsFil);
                                        }}></i>
                                    </div>
                                })
                                :
                                <></>
                        }
                        <div className="image-item to-upload">
                            <small>1MB máx, (.jpg, .jpeg, .png)</small>
                            <Input type="file" accept=".jpg, .jpeg, .png"
                                onChange={(ev: any) => {
                                    if (ev.target.files.length > 0) {
                                        toast.info("La imagen se está subiendo")
                                        madePost(
                                            {
                                                key: "upload-image-product",
                                                image: ev.target.files![0],
                                                name: product.name.toLowerCase().replaceAll(" ", "-") + "-" + images.length + "." + ev.target.files![0].name.split(".").pop()
                                            },
                                            "AdminPagos.php"
                                        ).then(r => {
                                            let res = r as ResponseGeneral;

                                            if (res.RESULT) {
                                                let p: Product = clone(product)

                                                setProduct(p);
                                                setIsEditting("");

                                                let imgs = clone(images);
                                                imgs.push(res.VALUE);

                                                setImages(imgs);

                                                toast.success("La imagen se ha subido con exito")
                                            } else {
                                                toast.error("La imagen no se ha podido subir")
                                            }

                                            ev.target.value = "";
                                        }).catch(Catch)
                                    }
                                }} />
                        </div>
                    </div>
                    :
                    <></>
            }
            {/*
                    (isEditting.length > 0 && isEditting === "image" ?
                        <div className="edit-area d-flex flex-row align-items-center">

                            <Input type="file" accept=".png, .jpg, .jpeg" innerRef={imageRef} />
                            <Button color="blue" onClick={() => {
                                if (imageRef.current.files!.length > 0) {
                                    toast.info("La imagen se está subiendo")
                                    madePost(
                                        {
                                            key: "upload-image-product",
                                            image: imageRef.current.files![0],
                                            name: product.name.toLowerCase().replaceAll(" ", "-") + "." + imageRef.current.files![0].name.split(".").pop()
                                        },
                                        "AdminPagos.php"
                                    ).then(r => {
                                        let res = r as ResponseGeneral;

                                        if (res.RESULT) {
                                            let p: Product = clone(product)

                                            p.images[0] = res.VALUE;
                                            setProduct(p);
                                            setIsEditting("");
                                            toast.success("La imagen se ha subido con exito")
                                        } else {
                                            toast.error("La imagen no se ha podido subir")
                                        }
                                    }).catch(Catch)
                                } else {
                                    toast.error("No seleccionaste ningún archivo")
                                }
                            }}>
                                Subir
                            </Button>
                        </div>
                        :
                        <div className="image">
                            <img src={product.images[0]} alt={product.name} />

                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <small className="mx-2">Cambiar {"->"}</small>
                                <i className="bi bi-x" onClick={() => {
                                    setIsEditting("image");
                                }}></i>
                            </div>
                        </div>)

                    :
                    <></>
                            */}
            <div>
                {
                    isEditting.length > 0 && isEditting === "name" ?
                        <div className="edit-area w-100">
                            <Input placeholder="Nombre del producto" type="text" defaultValue={product.name} innerRef={nameRef} onKeyDown={(key: any) => {
                                if (key.key == "Enter") {
                                    let p: Product = clone(product)

                                    p.name = nameRef.current.value;
                                    setProduct(p);
                                    setIsEditting("");
                                }
                            }} />
                            <Button color="blue" onClick={() => {
                                let p: Product = clone(product)

                                p.name = nameRef.current.value;
                                setProduct(p);
                                setIsEditting("");
                            }}>
                                Cambiar
                            </Button>
                        </div>
                        :
                        <span onClick={() => {
                            setIsEditting("name")

                            setTimeout(() => {
                                nameRef.current.focus();
                                nameRef.current.select();
                            }, 100)
                        }}>{product.name} <small className="note">(Click para editar)</small></span>
                }

                {
                    isEditting.length > 0 && isEditting === "description" ?
                        <div className="edit-area w-100">
                            <Input innerRef={descriptionRef} placeholder="Descripción del producto" type="text" defaultValue={product.description} onKeyDown={(key: any) => {
                                if (key.key == "Enter") {
                                    let p: Product = clone(product)

                                    p.description = descriptionRef.current.value;
                                    setProduct(p);
                                    setIsEditting("");
                                }
                            }} />
                            <Button color="blue" onClick={() => {
                                let p: Product = clone(product)

                                p.description = descriptionRef.current.value;
                                setProduct(p);
                                setIsEditting("");
                            }}>
                                Cambiar
                            </Button>
                        </div>
                        :
                        <p className="card-description text-start w-100" onClick={() => {
                            setIsEditting("description")

                            setTimeout(() => {
                                descriptionRef.current.focus();
                                descriptionRef.current.select();
                            }, 100)
                        }}>{product.description} <small className="note">(Click para editar)</small></p>
                }

                <div className="edit-area w-100">
                    <span className="d-block" style={{ marginRight: "1rem" }}>Categoria: </span>
                    <Input type="select" defaultValue={product.category} onChange={(ev: any) => {
                        let p: Product = clone(product)

                        p.category = ev.target.value;
                        setProduct(p);
                    }}>
                        {
                            Categories.map((c: { title: string, code: string }) => {
                                return <option value={c.code} selected={product.category == c.code}>{c.title}</option>
                            })
                        }
                    </Input>
                </div>

                {
                    isEditting.length > 0 && isEditting === "price" ?
                        <div className="edit-area w-100">
                            <Input placeholder="Precio del producto (En Bs.)" type="number" innerRef={priceRef} defaultValue={product.price} onKeyDown={(key: any) => {
                                if (key.key == "Enter") {
                                    let p: Product = clone(product)

                                    p.price = parseInt(priceRef.current.value);
                                    setProduct(p);
                                    setIsEditting("");
                                }
                            }} />
                            <Button color="blue" onClick={() => {
                                let p: Product = clone(product)

                                p.price = parseInt(priceRef.current.value);
                                setProduct(p);
                                setIsEditting("");
                            }}>
                                Cambiar
                            </Button>
                        </div>
                        :
                        <p className="card-price text-end w-100" onClick={() => {
                            setIsEditting("price");
                            setTimeout(() => {
                                priceRef.current.focus();
                                priceRef.current.select();
                            }, 100)
                        }}>Precio: {product.price} Bs. <small className="note">(Click para editar)</small></p>
                }

                <hr className="w-100" />

                <span>Detalles del producto</span>
                {
                    product.winnings ?
                        product.winnings.length > 0 ?
                            product.winnings.map((detail: Winning, index: number) => {
                                return <div className="detail-card">
                                    <span>{detail.text}</span>
                                    <i className="bi bi-x" onClick={() => {
                                        let p: Product = clone(product);

                                        p.winnings = p.winnings.filter((win: Winning, ii: number) => {
                                            return index !== ii;
                                        });

                                        setProduct(p);
                                    }}></i>
                                </div>
                            })
                            :
                            <label>No puedes dejar esta campo vacio</label>
                        :
                        <></>
                }

                <div className="edit-area w-100">
                    <Input placeholder="Ventaja del producto" className="my-2" type="text" innerRef={addSkillRef} />
                    <Button color="blue" onClick={() => {
                        let p: Product = clone(product);
                        p.winnings.push({ icon: "check", text: addSkillRef.current.value });

                        setProduct(p);

                        setTimeout(() => {
                            addSkillRef.current.value = "";
                        }, 200)
                    }}>Agregar</Button>
                </div>

                <hr className="w-100" />

                <span>Detalles del proveedor</span>
                <div className="edit-area w-100 " style={{ flexDirection: "column", gap: ".5rem" }}>
                    <Input defaultValue={provider ? provider.enterprise : ""} placeholder="Empresa u organización" type="text" style={{ margin: 0 }}
                        onInput={(ev: any) => { providerChange('enterprise', ev.target.value) }} />

                    <Input defaultValue={provider ? provider.location : ""} placeholder="Ubicación" type="text" style={{ margin: 0 }}
                        onInput={(ev: any) => { providerChange('location', ev.target.value) }} />

                    <Input defaultValue={provider ? provider.locationInfo : ""} placeholder="Detalles de la ubicación (Para plantas de produccion endemica)" type="text" style={{ margin: 0 }}
                        onInput={(ev: any) => { providerChange('locationInfo', ev.target.value) }} />

                    <Input defaultValue={provider ? provider.sanitaryCode : ""} placeholder="Registro sanitario" type="text" style={{ margin: 0 }}
                        onInput={(ev: any) => { providerChange('sanitaryCode', ev.target.value) }} />

                    <Input defaultValue={provider ? provider.others : ""} placeholder="Más información del producto o proveedor" type="textarea" style={{ margin: 0 }}
                        onInput={(ev: any) => { providerChange('others', ev.target.value) }} />
                </div>

                <hr className="w-100" />

                <div className="d-flex flex-row justify-content-end mt-4 w-100">
                    <Button onClick={() => {
                        //setProduct({} as product)
                        props.onCancel();
                    }} className="w-auto mx-2" color="secondary" >Cancelar</Button>
                    <Button onClick={save} disabled={!isValid} className="w-auto" color="blue" >Guardar</Button>

                </div>

            </div>
        </div>
    } else {
        return <div className={"card-component " + props.className} >
            <img src={image} alt={props.name} />
            <div>
                <span className="card-title">{props.name}</span>
                <p className="card-description">{props.description!.slice(0, 60) + (props.description!.length > 60 ? "..." : "")}</p>

                <p className="card-price">{props.price} Bs.</p>

                <Button onClick={() => { props.onEdit!(props.product) }} className="w-100 mt-2" color="blue" >Editar</Button>
                <Button onClick={manage} className="w-100 mt-2" color={product.active == 1 ? "danger" : "secondary"} >{product.active == 1 ? "Deshabilitar" : "Habilitar"}</Button>
            </div>
        </div>
    }
}

const Card = (props: {
    title?: string,
    description?: string,
    image?: File | string,
    price?: string,
    course: Course,
    className?: string,
    onEdit?: (course: Course) => any,
    onUpdate?: (course: Course) => any,
    isEdit?: boolean,
    onCancel: () => any

}) => {
    const [image, setImage] = useState(props.image as any);
    const [course, setCourse] = useState(props.course);

    const [isEditting, setIsEditting] = useState("");
    const [isTitleSetted, setIsTitleSetted] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const dateRef = useRef({} as HTMLInputElement);
    const dateEndRef = useRef({} as HTMLInputElement);

    const nameRef = useRef({} as HTMLInputElement);
    const descriptionRef = useRef({} as HTMLInputElement);
    const priceRef = useRef({} as HTMLInputElement);
    const addSkillRef = useRef({} as HTMLInputElement);
    const imageRef = useRef({} as HTMLInputElement);
    const locationRef = useRef({} as HTMLInputElement);

    const manage = () => {
        if (course.active == 1) {
            let rrr = confirm("Está seguro de deshabilitar este curso? Ya no se mostrará en la página web");

            if (rrr) {
                madePost(
                    {
                        key: "manage-course",
                        state: 0,
                        course_code: course.course_code
                    },
                    "AdminPagos.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        let p = clone(course);
                        p.active = 0;

                        setCourse(p);
                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(Catch)
            }
        } else {
            let rrr = confirm("Está seguro de habilitar este curso? Se volverá a mostrar en la página web")

            if (rrr) {
                madePost(
                    {
                        key: "manage-course",
                        state: 1,
                        course_code: course.course_code
                    },
                    "AdminPagos.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        let p = clone(course);
                        p.active = 1;

                        setCourse(p);
                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(Catch)
            }
        }
    }

    const analizeCourse = () => {
        try {
            if (course.cover_image.length > 0 &&
                course.description.length > 0 &&
                course.details.length > 0 &&
                course.price.toString().length > 0 &&
                course.title.length > 0
            ) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } catch (err) {
            setIsValid(false);
        }

        try {
            if (course.title.length > 0 && course.title != "Nombre del curso") {
                setIsTitleSetted(true);
            } else {
                setIsTitleSetted(false);
            }
        } catch (err) {
            setIsTitleSetted(false);
        }
    }

    const save = () => {
        madePost(
            {
                key: course.course_code == -1 ? "create-course" : "edit-course",
                course_code: course.course_code,
                title: course.title,
                description: course.description,
                type: course.type,
                location: (locationRef.current.value != "Ubicación" ? locationRef.current.value : null),
                start_date: dateRef.current.value.length > 0 ? dateRef.current.value : "0000-00-00",
                end_date: dateEndRef.current.value.length > 0 ? dateEndRef.current.value : "0000-00-00",
                details: JSON.stringify(course.details),
                cover_image: course.cover_image,
                price: course.price,
                active: course.active
            },
            "AdminPagos.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("El curso se ha " + (course.course_code == -1 ? "creado" : "editado") + " correctamente");

                props.onUpdate!(res.VALUE);
            } else {
                toast.error("El curso no se ha podido editar")
            }
        }).catch(Catch)
    }
    useEffect(() => {
        let p: Course = clone(props.course);

        try {
            if (typeof p.details == "string") {
                p.details = JSON.parse(p.details as unknown as any);
            }
        } catch (err) { }
        setImage(props.image);
        setCourse(p)
    }, [props])

    useEffect(() => {
        analizeCourse()
    }, [course]);

    if (props.isEdit) {
        return <div className="card-component card-edit">
            {
                isTitleSetted ?
                    (isEditting.length > 0 && isEditting === "image" ?
                        <div className="edit-area d-flex flex-row align-items-center">
                            <Input type="file" accept=".png, .jpg, .jpeg" innerRef={imageRef} />
                            <Button color="blue" onClick={() => {
                                if (imageRef.current.files!.length > 0) {
                                    toast.info("La imagen se está subiendo")
                                    madePost(
                                        {
                                            key: "upload-image-course",
                                            image: imageRef.current.files![0],
                                            name: course.title.toLowerCase().replaceAll(" ", "-") + "." + imageRef.current.files![0].name.split(".").pop()
                                        },
                                        "AdminPagos.php"
                                    ).then(r => {
                                        let res = r as ResponseGeneral;

                                        if (res.RESULT) {
                                            let p: Course = clone(course)

                                            p.cover_image = res.VALUE;
                                            setCourse(p);
                                            setIsEditting("");
                                            toast.success("La imagen se ha subido con exito")
                                        } else {
                                            toast.error("La imagen no se ha podido subir")
                                        }
                                    }).catch(Catch)
                                } else {
                                    toast.error("No seleccionaste ningún archivo")
                                }
                            }}>
                                Subir
                            </Button>
                        </div>
                        :
                        <div className="image">
                            <img src={course.cover_image} alt={course.title} />

                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <small className="mx-2">Cambiar {"->"}</small>
                                <i className="bi bi-x" onClick={() => {
                                    setIsEditting("image");
                                }}></i>
                            </div>
                        </div>)

                    :
                    <></>
            }
            <div>
                {
                    isEditting.length > 0 && isEditting === "name" ?
                        <div className="edit-area w-100">
                            <Input placeholder="Nombre del curso" type="text" defaultValue={course.title} innerRef={nameRef} onKeyDown={(key: any) => {
                                if (key.key == "Enter") {
                                    let p: Course = clone(course)

                                    p.title = nameRef.current.value;
                                    setCourse(p);
                                    setIsEditting("");
                                }
                            }} />
                            <Button color="blue" onClick={() => {
                                let p: Course = clone(course)

                                p.title = nameRef.current.value;
                                setCourse(p);
                                setIsEditting("");
                            }}>
                                Cambiar
                            </Button>
                        </div>
                        :
                        <span onClick={() => {
                            setIsEditting("name")

                            setTimeout(() => {
                                nameRef.current.focus();
                                nameRef.current.select();
                            }, 100)
                        }}>{course.title} <small className="note">(Click para editar)</small></span>
                }

                {
                    isEditting.length > 0 && isEditting === "description" ?
                        <div className="edit-area w-100">
                            <Input placeholder="Descripción del curso" type="text" innerRef={descriptionRef} defaultValue={course.description} onKeyDown={(key: any) => {
                                if (key.key == "Enter") {
                                    let p: Course = clone(course)

                                    p.description = descriptionRef.current.value;
                                    setCourse(p);
                                    setIsEditting("");
                                }
                            }} />
                            <Button color="blue" onClick={() => {
                                let p: Course = clone(course)

                                p.description = descriptionRef.current.value;
                                setCourse(p);
                                setIsEditting("");
                            }}>
                                Cambiar
                            </Button>
                        </div>
                        :
                        <p className="card-description text-start w-100" onClick={() => {
                            setIsEditting("description")

                            setTimeout(() => {
                                descriptionRef.current.focus();
                                descriptionRef.current.select();
                            }, 100)
                        }}>{course.description} <small className="note">(Click para editar)</small></p>
                }

                <small className="note text-start w-100">Fecha y hora de inicio</small>
                <input type="datetime-local" ref={dateRef} className="form-control mb-4" defaultValue={course.start_date} onChange={(ev: any) => {
                    let p: Course = clone(course)

                    p.start_date = dateRef.current.value;
                    setCourse(p);
                }} />

                <small className="note text-start w-100">Fecha y hora de conclusión</small>
                <input type="datetime-local" ref={dateEndRef} className="form-control mb-4" defaultValue={course.end_date} onChange={(ev: any) => {
                    let p: Course = clone(course)

                    p.end_date = dateEndRef.current.value;
                    setCourse(p);
                }} />

                {
                    course.type == "virtual" ?
                        <></>
                        :
                        <>
                            {
                                isEditting.length > 0 && isEditting === "location" ?
                                    <div className="edit-area w-100">
                                        <Input placeholder="Descripción del curso" type="text" innerRef={locationRef} defaultValue={course.location} onKeyDown={(key: any) => {
                                            if (key.key == "Enter") {
                                                let p: Course = clone(course)

                                                p.location = locationRef.current.value;
                                                setCourse(p);
                                                setIsEditting("");
                                            }
                                        }} />
                                        <Button color="blue" onClick={() => {
                                            let p: Course = clone(course)

                                            p.location = locationRef.current.value;
                                            setCourse(p);
                                            setIsEditting("");
                                        }}>
                                            Cambiar
                                        </Button>
                                    </div>
                                    :
                                    <p className="card-description text-start w-100" onClick={() => {
                                        setIsEditting("location")

                                        setTimeout(() => {
                                            locationRef.current.focus();
                                            locationRef.current.select();
                                        }, 100)
                                    }}>{course.location} <small className="note">(Click para editar)</small></p>
                            }
                        </>
                }

                {
                    isEditting.length > 0 && isEditting === "price" ?
                        <div className="edit-area w-100">
                            <Input placeholder="Precio del curso (En Bs.)" type="number" innerRef={priceRef} defaultValue={course.price} onKeyDown={(key: any) => {
                                if (key.key == "Enter") {
                                    let p: Course = clone(course)

                                    p.price = parseInt(priceRef.current.value);
                                    setCourse(p);
                                    setIsEditting("");
                                }
                            }} />
                            <Button color="blue" onClick={() => {
                                let p: Course = clone(course)

                                p.price = parseInt(priceRef.current.value);
                                setCourse(p);
                                setIsEditting("");
                            }}>
                                Cambiar
                            </Button>
                        </div>
                        :
                        <p className="card-price text-end w-100" onClick={() => {
                            setIsEditting("price");
                            setTimeout(() => {
                                priceRef.current.focus();
                                priceRef.current.select();
                            }, 100)
                        }}>Precio: {course.price} Bs. <small className="note">(Click para editar)</small></p>
                }

                <hr className="w-100" />

                <span>Detalles</span>
                {
                    course.details ?
                        course.details.length > 0 ?
                            course.details.map((detail: Winning, index: number) => {
                                return <div className="detail-card">
                                    <span>{detail.text}</span>
                                    <i className="bi bi-x" onClick={() => {
                                        let p: Course = clone(course);

                                        p.details = p.details.filter((win: Winning, ii: number) => {
                                            return index !== ii;
                                        });

                                        setCourse(p);
                                    }}></i>
                                </div>
                            })
                            :
                            <label>No puedes dejar esta campo vacio</label>
                        :
                        <></>
                }

                <div className="edit-area w-100">
                    <Input placeholder="Ventaja del curso" className="my-2" type="text" innerRef={addSkillRef} />
                    <Button color="blue" onClick={() => {
                        let p: Course = clone(course);
                        p.details.push({ icon: "check", text: addSkillRef.current.value });

                        setCourse(p);

                        setTimeout(() => {
                            addSkillRef.current.value = "";
                        }, 200)
                    }}>Agregar</Button>
                </div>

                <hr className="w-100" />

                <div className="d-flex flex-row justify-content-end mt-4 w-100">
                    <Button onClick={() => {
                        //setCourse({} as Course)
                        props.onCancel();
                    }} className="w-auto mx-2" color="secondary" >Cancelar</Button>
                    <Button onClick={save} disabled={!isValid} className="w-auto" color="blue" >Guardar</Button>

                </div>

            </div>
        </div>
    } else {
        return <div className={"card-component " + props.className} >
            <img src={image} alt={props.title} />
            <div>
                <span className="card-title">{props.title}</span>
                <p className="card-description">{props.description!.slice(0, 60) + (props.description!.length > 60 ? "..." : "")}</p>

                <p className="card-price">{props.price} Bs.</p>

                <Button onClick={() => { props.onEdit!(props.course) }} className="w-100 mt-2" color="blue" >Editar</Button>
                <Button onClick={manage} className="w-100 mt-2" color={course.active == 1 ? "danger" : "secondary"} >{course.active == 1 ? "Deshabilitar" : "Habilitar"}</Button>
            </div>
        </div>
    }
}

export default Card;

import Quill from "quill";

import DatePicker from "react-datepicker";
import format from "date-fns/format";

import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from "reactstrap";
import { imgToBase64, notaDestacadaType, notaDiaType, userType } from "../theme/variables";

import "./scss/AdminForms.scss";
import "react-datepicker/dist/react-datepicker.css";
import { CreateAlertsURL, CreateEdicionesURL, CreateRankingsURL, SelectablePosNotaDestacada, TextEditor } from "./comps";

export let AddWorker = (dt: { onCancel: () => any, onSave: (newWorker: any) => any }) => {
    return <>
        <ModalBody>
            <div className="add-worker-component">
                <FormGroup>
                    <Label>
                        Nombre
                    </Label>
                    <Input type="text" placeholder="Nombre" id="nombre-addworker" />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Email
                    </Label>
                    <Input type="text" placeholder="Email" id="email-addworker" onChange={(ev:any)=>{
                        let usrr = document.getElementById("user-add-worker-showed") as HTMLSpanElement;
                        usrr.innerHTML = "<mark>".concat(ev.target.value).concat("</mark>");
                    }} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Área
                    </Label>
                    <Input type="text" placeholder="Área" id="area-addworker" />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Cargo
                    </Label>
                    <Input type="text" placeholder="Cargo" id="cargo-addworker" />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Celular
                    </Label>
                    <Input type="text" placeholder="Celular" id="celular-addworker" onChange={(ev:any)=>{
                        let pss = document.getElementById("contra-add-worker-showed") as HTMLSpanElement;
                        pss.innerHTML = "<mark>".concat(ev.target.value).concat("</mark>");
                    }} />
                </FormGroup>
                <Row>
                    <Row>
                        <Label>
                            Rol
                        </Label>
                    </Row>
                    <Row className="container-checkboxes-add-worker">

                        <Col xs="12" sm="4" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label for="rol-ediciones-addworker">
                                    Ediciones
                                </Label>
                                <Input type="checkbox" className="mx-1" placeholder="Rol" id="rol-ediciones-addworker" />
                            </FormGroup></Col>
                        <Col xs="12" sm="4" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label for="rol-rankings-addworker">
                                    Rankings
                                </Label>
                                <Input type="checkbox" className="mx-1" placeholder="Rol" id="rol-rankings-addworker" />
                            </FormGroup>
                        </Col>
                        <Col xs="12" sm="4" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label for="rol-notas-addworker">
                                    Notas
                                </Label>
                                <Input type="checkbox" className="mx-1" placeholder="Rol" id="rol-notas-addworker" />
                            </FormGroup>
                        </Col>
                        <Col xs="12" sm="6" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label for="rol-admin-addworker">
                                    Administrador
                                </Label>
                                <Input type="checkbox" className="mx-1" placeholder="Rol" id="rol-admin-addworker" />
                            </FormGroup>
                        </Col>
                        <Col xs="12" sm="6" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label for="rol-calidad-addworker">
                                    Calidad
                                </Label>
                                <Input type="checkbox" className="mx-1" placeholder="Calidad" id="rol-calidad-addworker" />
                            </FormGroup>
                        </Col>
                    </Row>
                </Row>

                <Row>
                    <Label><strong>CREDENCIALES: </strong><br/>Usuario: <span id="user-add-worker-showed"></span> <br/>Contraseña: <span id="contra-add-worker-showed"></span></Label>
                </Row>
            </div>
        </ModalBody>

        <FooterAdminFormModal onSave={() => {
            let INombre = document.getElementById("nombre-addworker") as HTMLInputElement;
            let IEmail = document.getElementById("email-addworker") as HTMLInputElement;
            let IArea = document.getElementById("area-addworker") as HTMLInputElement;
            let ICargo = document.getElementById("cargo-addworker") as HTMLInputElement;
            let ICel = document.getElementById("celular-addworker") as HTMLInputElement;

            let IRolAdmin = document.getElementById("rol-admin-addworker") as HTMLInputElement;
            let IRolEdiciones = document.getElementById("rol-ediciones-addworker") as HTMLInputElement;
            let IRolRankings = document.getElementById("rol-rankings-addworker") as HTMLInputElement;
            let IRolNotas = document.getElementById("rol-notas-addworker") as HTMLInputElement;
            let IRolCalidad = document.getElementById("rol-calidad-addworker") as HTMLInputElement;

            let IRol: string = (IRolAdmin.checked ? "admin" : (IRolEdiciones.checked ? "ediciones|" : "") + (IRolRankings.checked ? "rankings|" : "") + (IRolNotas.checked ? "notas|" : "") + (IRolCalidad.checked ? "calidad|" : ""));

            dt.onSave({
                nombre: INombre.value,
                email: IEmail.value,
                area: IArea.value,
                cargo: ICargo.value,
                celular: ICel.value,
                rol: IRol
            });
        }} onCancel={dt.onCancel} />
    </>
}

export let EditWorker = (dt: { worker: userType, onCancel: () => any, onSave: (newWorker: any) => any }) => {
    return <>
        <ModalBody>
            <div className="edit-worker-component">
                <FormGroup>
                    <Label>
                        Nombre
                    </Label>
                    <Input type="text" placeholder="Nombre" id="nombre-addworker" defaultValue={dt.worker.nombre} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Email
                    </Label>
                    <Input type="text" placeholder="Email" id="email-addworker" defaultValue={dt.worker.email} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Área
                    </Label>
                    <Input type="text" placeholder="Área" id="area-addworker" defaultValue={dt.worker.area} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Cargo
                    </Label>
                    <Input type="text" placeholder="Cargo" id="cargo-addworker" defaultValue={dt.worker.cargo} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Celular
                    </Label>
                    <Input type="text" placeholder="Celular" id="celular-addworker" defaultValue={dt.worker.celular} />
                </FormGroup>
                <Row>
                    <Row>
                        <Label>
                            Rol
                        </Label>
                    </Row>
                    <Row>
                        <Col xs="4" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label>
                                    Ediciones
                                </Label>
                                <Input type="checkbox" className="mx-1"
                                    placeholder="Rol" id="rol-ediciones-addworker"
                                    defaultChecked={dt.worker.rol === "admin" ? true : dt.worker.rol.split("|").includes("ediciones")} />
                            </FormGroup></Col>
                        <Col xs="4" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label>
                                    Rankings
                                </Label>
                                <Input type="checkbox" className="mx-1"
                                    placeholder="Rol" id="rol-rankings-addworker"
                                    defaultChecked={dt.worker.rol === "admin" ? true : dt.worker.rol.split("|").includes("rankings")} />
                            </FormGroup>
                        </Col>
                        <Col xs="4" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label>
                                    Notas
                                </Label>
                                <Input type="checkbox" className="mx-1"
                                    placeholder="Rol" id="rol-notas-addworker"
                                    defaultChecked={dt.worker.rol === "admin" ? true : dt.worker.rol.split("|").includes("notas")} />
                            </FormGroup>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label>
                                    Administrador
                                </Label>
                                <Input type="checkbox" className="mx-1"
                                    placeholder="Rol" id="rol-admin-addworker"
                                    defaultChecked={dt.worker.rol === "admin" ? true : false} />
                            </FormGroup>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-center">
                            <FormGroup>
                                <Label>
                                    Calidad
                                </Label>
                                <Input type="checkbox" className="mx-1"
                                    placeholder="Rol" id="rol-calidad-addworker"
                                    defaultChecked={dt.worker.rol === "admin" ? true : dt.worker.rol.split("|").includes("calidad")} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Row>
            </div>
        </ModalBody>

        <FooterAdminFormModal onSave={() => {
            let INombre = document.getElementById("nombre-addworker") as HTMLInputElement;
            let IEmail = document.getElementById("email-addworker") as HTMLInputElement;
            let IArea = document.getElementById("area-addworker") as HTMLInputElement;
            let ICargo = document.getElementById("cargo-addworker") as HTMLInputElement;
            let ICel = document.getElementById("celular-addworker") as HTMLInputElement;

            let IRolAdmin = document.getElementById("rol-admin-addworker") as HTMLInputElement;
            let IRolEdiciones = document.getElementById("rol-ediciones-addworker") as HTMLInputElement;
            let IRolRankings = document.getElementById("rol-rankings-addworker") as HTMLInputElement;
            let IRolNotas = document.getElementById("rol-notas-addworker") as HTMLInputElement;
            let IRolCalidad = document.getElementById("rol-calidad-addworker") as HTMLInputElement;

            let IRol: string = (IRolAdmin.checked ? "admin" : (IRolEdiciones.checked ? "ediciones|" : "") + (IRolRankings.checked ? "rankings|" : "") + (IRolNotas.checked ? "notas|" : "") + (IRolCalidad.checked ? "calidad|" : ""));

            dt.onSave({
                nombre: INombre.value,
                email: IEmail.value,
                area: IArea.value,
                cargo: ICargo.value,
                celular: ICel.value,
                rol: IRol,
                user_code: dt.worker.user_code
            });
        }} onCancel={dt.onCancel} />
    </>
}

export let DeleteWorker = (dt: { onCancel: () => any, onDelete: () => any }) => {
    return <>
        <ModalBody>
            <div className="delete-worker-component">
                <Label>
                    ¿Estás seguro de eliminar al usuario?
                </Label>
            </div>
        </ModalBody>

        <FooterAdminFormModal onSave={dt.onDelete} onCancel={dt.onCancel} otherText="Eliminar" />
    </>
}

export let EdicionesForm = (dt: { onCancel: () => any, onSave: (action: "ediciones" | "informes" | "separatas", values: FormData) => any, values?: any }) => {
    const [selectEdicion, setSelectEdicion] = useState("ediciones" as "ediciones" | "informes" | "separatas");
    const [gestion, setGestion] = useState("2022");

    const [selectedDate, setSelectedDate] = useState(dt.values ? dt.values.fecha : format(new Date(), "dd-MM-yyyy"));
    const [startDate, setStartDate] = useState(dt.values ? new Date(parseInt(dt.values.fecha.split("-")[2]), parseInt(dt.values.fecha.split("-")[1]) - 1, parseInt(dt.values.fecha.split("-")[0])) : new Date());

    const [updateMedia, setUpdateMedia] = useState(false);

    return <>
        <ModalBody>
            <div className="ediciones-form-component">
                <Input className="my-3" type="select" defaultValue={dt.values ? dt.values.tipo : "ediciones"} onChange={(ev: any) => {
                    setSelectEdicion(ev.target.value);
                }}>
                    <option value="ediciones">Ediciones</option>
                    <option value="informes">Informes</option>
                    <option value="separatas">Separatas</option>
                </Input>
                <Input className="my-3" type="text" placeholder="Título" id="titulo-ediciones" maxLength={150} defaultValue={dt.values ? dt.values.titulo : ""}></Input>
                <Input className="my-3" type="text" placeholder="Descripción" id="descripcion-ediciones" defaultValue={dt.values ? dt.values.descripcion : ""}></Input>

                <Row className="my-3">
                    <Col xs="6" >
                        <Input type="select" id="gestion-ediciones" defaultValue={dt.values ? dt.values.gestion : "2022"} onChange={(ev: any) => {
                            setGestion(ev.target.value);
                        }} >
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                        </Input>
                    </Col>
                    <Col xs="6">
                        <DatePicker selected={startDate} className="form-control" id="fecha-ediciones" dateFormat="dd/MM/yyyy" onChange={(date: Date) => {
                            setStartDate(date);
                            setSelectedDate(format(date, "dd-MM-yyyy"))
                        }} />
                        {/*<Input type="date" id="fecha-ediciones" />*/}
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col xs="6">
                        <Input id="paginas-ediciones" type="number" placeholder="Nro de páginas" defaultValue={dt.values ? dt.values.nropag : ""} />
                    </Col>

                    <Col xs="6">
                        <Input id="numero-ediciones" type="text" defaultValue={dt.values ? dt.values.nro : ""} placeholder={selectEdicion === "ediciones" ? "Número de Edición" : selectEdicion === "informes" ? "Número de Informe" : "Número de separata"} />
                    </Col>

                </Row>

                {dt.values ?
                    <Row className="mt-4 mb-1 mx-0">
                        <FormGroup check>
                            <Input type="checkbox" id="update-img-notas" className="col-1" defaultChecked={updateMedia} onChange={(ev: any) => {
                                setUpdateMedia(ev.target.checked);
                            }} />
                            <Label for="update-img-notas" className="col-11">Actualizar el contenido multimedia</Label>
                        </FormGroup>
                    </Row>
                    :
                    <></>
                }

                <Row>
                    <Col xs="6">
                        <FormGroup className="d-flex flex-column align-items-start">
                            <Label>Portada</Label>
                            <Input type="file" accept=".jpg, .jpeg, .png" id="portada-img-ediciones" disabled={dt.values ? !updateMedia : false} onChange={(ev: any) => {
                                let limitSize = 4 * 1024 * 1024;
                                if (ev.target.files![0].size > limitSize) {
                                    toast.error("Superaste el límite de tamaño de archivo de 4MB");
                                    ev.target.value = "";
                                }
                            }} />
                            <Label className="text-end w-100">Máx 4MB</Label>
                        </FormGroup>
                    </Col>
                    <Col xs="6">
                        <FormGroup className="d-flex flex-column align-items-start">
                            <Label>{"Portada (Redes)"}</Label>
                            <Input type="file" accept=".jpg, .jpeg, .png" id="portadaredes-img-ediciones" disabled={dt.values ? !updateMedia : false} onChange={(ev: any) => {
                                let limitSize = 300 * 1024;
                                if (ev.target.files![0].size > limitSize) {
                                    toast.error("Superaste el límite de tamaño de archivo de 300KB");
                                    ev.target.value = "";
                                }
                            }} />
                            <Label className="text-end w-100">Máx 300KB</Label>
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup className="d-flex flex-column align-items-start">
                            <Label>PDF</Label>
                            <Input type="file" accept=".pdf" id="pdf-file-ediciones" disabled={dt.values ? !updateMedia : false} onChange={(ev: any) => {
                                let limitSize = 30 * 1024 * 1024;
                                if (ev.target.files![0].size > limitSize) {
                                    toast.error("Superaste el límite de tamaño de archivo de 30MB");
                                    ev.target.value = "";
                                }
                            }} />
                            <Label className="text-end w-100">Máx 30MB</Label>
                        </FormGroup>
                    </Col>
                </Row>

            </div>
            {dt.values ?
                <CreateEdicionesURL style={{ width: "unset" }} url={dt.values.url} />
                :
                <></>
            }
        </ModalBody>

        <FooterAdminFormModal onSave={() => {

            let titulo = document.getElementById("titulo-ediciones") as HTMLInputElement;
            let des = document.getElementById("descripcion-ediciones") as HTMLInputElement;

            let nroPaginas = document.getElementById("paginas-ediciones") as HTMLInputElement;
            let nro = document.getElementById("numero-ediciones") as HTMLInputElement;

            let portadaImg = document.getElementById("portada-img-ediciones") as HTMLInputElement;
            let portadaRedesImg = document.getElementById("portadaredes-img-ediciones") as HTMLInputElement;
            let pdf = document.getElementById("pdf-file-ediciones") as HTMLInputElement;

            let form = new FormData();
            form.append("ed_tipo", selectEdicion);
            form.append("ed_titulo", titulo.value);
            form.append("ed_descripcion", des.value);
            form.append("ed_gestion", gestion);
            form.append("ed_fecha", selectedDate);
            form.append("ed_nropag", nroPaginas.value);
            form.append("ed_nro", nro.value);

            form.append("isEdit", dt.values ? "1" : "0");
            if (dt.values) {
                form.append("url", dt.values.url);
                form.append("codigo_elemento", dt.values.codigo_elemento.toString());
            }

            if (dt.values) {
                if (updateMedia) {
                    imgToBase64(portadaImg, (imgportada) => {
                        form.append("ed_portada_img", imgportada);

                        if (portadaRedesImg.files!.length > 0) {
                            form.append("ed_portadaredes_img", portadaRedesImg.files![0]);
                            imgToBase64(pdf, (pdfbase) => {
                                form.append("ed_pdf", pdfbase);

                                dt.onSave(selectEdicion, form);
                            })
                        } else {
                            toast.error("Porfavor, rellena todos los campos");
                        }
                    })
                } else {
                    dt.onSave(selectEdicion, form);
                }
            } else {
                imgToBase64(portadaImg, (imgportada) => {
                    form.append("ed_portada_img", imgportada);

                    if (portadaRedesImg.files!.length > 0) {
                        form.append("ed_portadaredes_img", portadaRedesImg.files![0]);
                        imgToBase64(pdf, (pdfbase) => {
                            form.append("ed_pdf", pdfbase);

                            dt.onSave(selectEdicion, form);
                        })
                    } else {
                        toast.error("Porfavor, rellena todos los campos");
                    }
                })
            }

        }} onCancel={dt.onCancel} />
    </>
}

export let RankingsForm = (dt: { onCancel: () => any, onSave: (action: "empresas" | "financiero", values: FormData) => any, values?: any }) => {
    const [selectEdicion, setSelectEdicion] = useState("empresas" as "empresas" | "financiero");
    const [gestion, setGestion] = useState("2022");
    const [selectedDate, setSelectedDate] = useState(dt.values ? dt.values.fecha : format(new Date(), "dd-MM-yyyy"));
    const [startDate, setStartDate] = useState(dt.values ? new Date(parseInt(dt.values.fecha.split("-")[2]), parseInt(dt.values.fecha.split("-")[1]) - 1, parseInt(dt.values.fecha.split("-")[0])) : new Date());

    const [updateMedia, setUpdateMedia] = useState(false);

    return <>
        <ModalBody>
            <div className="rankings-form-component">
                <Input className="my-3" type="select" onChange={(ev: any) => {
                    setSelectEdicion(ev.target.value);
                }} defaultValue={dt.values ? dt.values.tipo : ""}>
                    <option value="empresas">Empresas</option>
                    <option value="financiero">Financiero</option>
                </Input>
                <Input className="my-3" type="text" placeholder="Título" id="titulo-rankings" maxLength={150} defaultValue={dt.values ? dt.values.titulo : ""}></Input>
                <Input className="my-3" type="text" placeholder="Descripción" id="descripcion-rankings" defaultValue={dt.values ? dt.values.descripcion : ""}></Input>

                <Row className="my-3">
                    <Col xs="6" >
                        <Input type="select" id="gestion-rankings" onChange={(ev: any) => {
                            setGestion(ev.target.value);
                        }} defaultValue={dt.values ? dt.values.gestion : "2022"}>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                        </Input>
                    </Col>
                    <Col xs="6">
                        <DatePicker selected={startDate} className="form-control" id="fecha-rankings" dateFormat="dd/MM/yyyy" onChange={(date: Date) => {
                            setStartDate(date);
                            setSelectedDate(format(date, "dd-MM-yyyy"))
                        }} />

                    </Col>
                </Row>

                <Row className="my-3">
                    <Col xs="6">
                        <Input id="paginas-rankings" type="number" placeholder="Nro de páginas" defaultValue={dt.values ? dt.values.nropag : undefined} />
                    </Col>

                    <Col xs="6">
                        <Input id="numero-rankings" type="text" defaultValue={dt.values ? dt.values.nro : ""} placeholder={selectEdicion === "empresas" ? "Número de Edición Empresas" : selectEdicion === "financiero" ? "Número de Edición Financiera" : ""} />
                    </Col>

                </Row>

                {dt.values ?
                    <Row className="mt-4 mb-1 mx-0">
                        <FormGroup check>
                            <Input type="checkbox" id="update-img-notas" className="col-1" defaultChecked={updateMedia} onChange={(ev: any) => {
                                setUpdateMedia(ev.target.checked);
                            }} />
                            <Label for="update-img-notas" className="col-11">Actualizar el contenido multimedia</Label>
                        </FormGroup>
                    </Row>
                    :
                    <></>
                }

                <Row>
                    <Col xs="6">
                        <FormGroup className="d-flex flex-column align-items-start">
                            <Label>Portada</Label>
                            <Input type="file" accept=".png, .jpeg, .jpg" id="portada-img-rankings" disabled={dt.values ? !updateMedia : false} onChange={(ev: any) => {
                                let limitSize = 4 * 1024 * 1024;
                                if (ev.target.files![0].size > limitSize) {
                                    toast.error("Superaste el límite de tamaño de archivo de 4MB");
                                    ev.target.value = "";
                                }
                            }} />

                            <Label className="text-end w-100">Máx 4MB</Label>
                        </FormGroup>
                    </Col>
                    <Col xs="6">
                        <FormGroup className="d-flex flex-column align-items-start">
                            <Label>{"Portada (Redes)"}</Label>
                            <Input type="file" accept=".png, .jpeg, .jpg" id="portadaredes-img-rankings" disabled={dt.values ? !updateMedia : false} onChange={(ev: any) => {
                                let limitSize = 300 * 1024;
                                if (ev.target.files![0].size > limitSize) {
                                    toast.error("Superaste el límite de tamaño de archivo de 300 KB");
                                    ev.target.value = "";
                                }
                            }} />

                            <Label className="text-end w-100">Máx 300KB</Label>
                        </FormGroup>
                    </Col>
                    <Col xs="12">
                        <FormGroup className="d-flex flex-column align-items-start">
                            <Label>PDF</Label>
                            <Input type="file" accept=".pdf" id="pdf-file-rankings" disabled={dt.values ? !updateMedia : false} onChange={(ev: any) => {
                                let limitSize = 30 * 1024 * 1024;
                                if (ev.target.files![0].size > limitSize) {
                                    toast.error("Superaste el límite de tamaño de archivo de 30MB");
                                    ev.target.value = "";
                                }
                            }} />
                            <Label className="text-end w-100">Máx 30MB</Label>
                        </FormGroup>
                    </Col>
                </Row>

            </div>

            {dt.values ?
                <CreateRankingsURL style={{ width: "unset" }} url={dt.values.url} />
                :
                <></>
            }
        </ModalBody>

        <FooterAdminFormModal onSave={() => {

            let titulo = document.getElementById("titulo-rankings") as HTMLInputElement;
            let des = document.getElementById("descripcion-rankings") as HTMLInputElement;

            let fecha = document.getElementById("fecha-rankings") as HTMLInputElement;
            let nroPaginas = document.getElementById("paginas-rankings") as HTMLInputElement;
            let nro = document.getElementById("numero-rankings") as HTMLInputElement;

            let portadaImg = document.getElementById("portada-img-rankings") as HTMLInputElement;
            let portadaRedesImg = document.getElementById("portadaredes-img-rankings") as HTMLInputElement;
            let pdf = document.getElementById("pdf-file-rankings") as HTMLInputElement;

            let form = new FormData();
            form.append("rk_tipo", selectEdicion);
            form.append("rk_titulo", titulo.value);
            form.append("rk_descripcion", des.value);
            form.append("rk_gestion", gestion);
            form.append("rk_fecha", selectedDate);
            form.append("rk_nropag", nroPaginas.value);
            form.append("rk_nro", nro.value);

            form.append("isEdit", dt.values ? "1" : "0");
            if (dt.values) {
                form.append("url", dt.values.url);
                form.append("codigo_elemento", dt.values.codigo_elemento.toString());

                if (updateMedia) {
                    imgToBase64(portadaImg, (imgportada) => {
                        form.append("rk_portada_img", imgportada);
                        if (portadaRedesImg.files?.length! > 0) {
                            form.append("rk_portadaredes_img", portadaRedesImg.files![0]);
                            imgToBase64(pdf, (pdfbase) => {
                                form.append("rk_pdf", pdfbase);

                                dt.onSave(selectEdicion, form);
                            })
                        } else {
                            toast.error("Porfavor, rellena todos los campos");
                        }
                    })
                } else {
                    dt.onSave(selectEdicion, form);
                }

            } else {
                imgToBase64(portadaImg, (imgportada) => {
                    form.append("rk_portada_img", imgportada);
                    if (portadaRedesImg.files?.length! > 0) {
                        form.append("rk_portadaredes_img", portadaRedesImg.files![0]);
                        imgToBase64(pdf, (pdfbase) => {
                            form.append("rk_pdf", pdfbase);

                            dt.onSave(selectEdicion, form);
                        })
                    } else {
                        toast.error("Porfavor, rellena todos los campos");
                    }
                })
            }

        }} onCancel={dt.onCancel} />
    </>
}

export let NotasForm = (dt: { onCancel: () => any, onSave: (action: "nota-dia" | "nota-destacada", values: FormData) => any, values?: notaDiaType }) => {

    const [selectNota, setSelectNota] = useState("nota-dia" as "nota-destacada" | "nota-dia");

    const [editor, setEditor] = useState(new Object as Quill);

    const [selectedDate, setSelectedDate] = useState(dt.values ? dt.values.fecha : format(new Date(), "dd-MM-yyyy"));
    const [startDate, setStartDate] = useState(dt.values ? new Date(parseInt(dt.values.fecha.split("-")[2]), parseInt(dt.values.fecha.split("-")[1]) - 1, parseInt(dt.values.fecha.split("-")[0])) : new Date());

    const [selectableDestacada, setSelectableDestacada] = useState({ open: false, target: new Object as HTMLInputElement, defaultNumber: 1 });

    const [updateMedia, setUpdateMedia] = useState(false);

    //Obtener: JSON.stringify(editor.getContents())
    //Set: editor.setContents(JSON.parse(''))

    const editorQuillCallback = (editor: Quill) => {
        setEditor(editor);
    }

    useLayoutEffect(() => {
        if (selectNota === "nota-destacada") {
            setSelectableDestacada({ open: false, target: document.getElementById("posicion-add-nota-destacada")! as HTMLInputElement, defaultNumber: 1 });
        }
    }, [selectNota])

    //Si es nota destacada se tiene imagen de icono y imagen de redes
    return <>
        <ModalBody>
            <div className="notas-form-component">
                <Input className="my-3" type="select" onChange={(ev: any) => {
                    setSelectNota(ev.target.value);
                }}>
                    <option value="nota-dia">Nota</option>
                    <option value="nota-destacada">Nota destacada</option>
                </Input>
                <Input className="my-3" type="text" maxLength={150} placeholder="Título" id="titulo-add-nota" defaultValue={dt.values ? dt.values.titulo : ""}></Input>
                <Input className="my-3" type="text" placeholder="Subtitulo" id="descripcion-add-nota" defaultValue={dt.values ? dt.values.descripcion : ""}></Input>

                {selectNota === "nota-destacada" ?
                    <Input className="my-3" type="text" placeholder="URL" id="url-add-nota-destacada"></Input>
                    :
                    <></>
                }

                <Row className="my-3">
                    {selectNota === "nota-destacada" ?
                        <Col id="posicion-seccion-nota-destacada" xs={selectNota === "nota-destacada" ? 6 : 0}>
                            <FormGroup onClick={(ev: any) => {
                                setSelectableDestacada({ target: selectableDestacada.target, open: !selectableDestacada.open, defaultNumber: parseInt(selectableDestacada.target.value) });
                            }}>
                                <SelectablePosNotaDestacada default={selectableDestacada.defaultNumber} open={selectableDestacada.open} target={selectableDestacada.target} onSelect={(pos: number) => {
                                    let inp = document.getElementById("posicion-add-nota-destacada") as HTMLInputElement;
                                    inp.value = pos.toString();
                                    setSelectableDestacada({ target: selectableDestacada.target, open: false, defaultNumber: pos });
                                }} />
                                <Label>Posición</Label>
                                <Input type="number" defaultValue={1} id="posicion-add-nota-destacada" onInput={(ev: any) => {
                                    setSelectableDestacada({ target: selectableDestacada.target, open: selectableDestacada.open, defaultNumber: parseInt(ev.target.value) > 0 && ev.target.value(ev.target.value) < 6 ? parseInt(ev.target.value) : 1 });
                                }}></Input>
                            </FormGroup>
                        </Col>
                        :
                        <></>
                    }
                    <Col xs={selectNota === "nota-destacada" ? 6 : 12}>
                        <FormGroup>
                            <Label>Fecha de publicación</Label>
                            <DatePicker selected={startDate} className="form-control" id="fecha-ediciones" dateFormat="dd/MM/yyyy" onChange={(date: Date) => {
                                setStartDate(date);
                                setSelectedDate(format(date, "dd-MM-yyyy"))
                            }} />
                            {/*<Input type="date" id="fecha-add-nota" defaultValue={dt.values ? dt.values.fecha : ""}></Input>*/}
                        </FormGroup>
                    </Col>
                </Row>

                {selectNota === "nota-dia" ? <TextEditor onObtain={editorQuillCallback} style={{ height: "20em" }} defaultValue={dt.values ? dt.values.editor_content : undefined} /> : <></>}

                {dt.values ?
                    <Row className="mt-3 mb-1 mx-0">
                        <FormGroup check>
                            <Input type="checkbox" id="update-img-notas" className="col-1" defaultChecked={updateMedia} onChange={(ev: any) => {
                                setUpdateMedia(ev.target.checked);
                            }} />
                            <Label for="update-img-notas" className="col-11">Actualizar el contenido multimedia</Label>
                        </FormGroup>
                    </Row>
                    :
                    <></>
                }

                {selectNota === "nota-dia" ?
                    <Row className="my-1">
                        <Col xs={6}>
                            <FormGroup>
                                <Label>Imagen de portada</Label>
                                <Input type="file" id="principalimg-add-nota-destacada" disabled={dt.values ? !updateMedia : false} onChange={(ev: any) => {
                                    let limitSize = 4 * 1024 * 1024;
                                    if (ev.target.files![0].size > limitSize) {
                                        toast.error("Superaste el límite de tamaño de archivo de 4MB");
                                        ev.target.value = "";
                                    }
                                }}
                                    accept=".jpg, .jpeg, .png"></Input>
                                <Label className="w-100 text-end mb-0">Máx 4MB</Label>
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <FormGroup>
                                <Label>Imagen redes (Para compartir)</Label>
                                <Input className="" type="file" id="redesimg-add-nota" disabled={dt.values ? !updateMedia : false} accept=".jpg, .jpeg, .png" onChange={(ev: any) => {
                                    let limitSize = 300 * 1024;
                                    if (ev.target.files![0].size > limitSize) {
                                        toast.error("Superaste el límite de tamaño de archivo de 300KB");
                                        ev.target.value = "";
                                    }
                                }}></Input>
                                <Label className="w-100 text-end mb-0">Máx 300KB</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <></>
                }

                {dt.values ?
                    <CreateAlertsURL style={{ width: "unset" }} url={dt.values.url} />
                    :
                    <></>
                }
            </div>
        </ModalBody>
        <FooterAdminFormModal onSave={() => {
            let values: FormData = new FormData();

            let tituloAdd = document.getElementById("titulo-add-nota") as HTMLInputElement;
            let descripcionAdd = document.getElementById("descripcion-add-nota") as HTMLInputElement;

            if (selectNota === "nota-destacada") {
                let posicionAdd = document.getElementById("posicion-add-nota-destacada") as HTMLInputElement;

                let urlAdd = document.getElementById("url-add-nota-destacada") as HTMLInputElement;

                values.append("nd_titulo", tituloAdd.value);
                values.append("nd_descripcion", descripcionAdd.value);
                values.append("nd_posicion", posicionAdd.value);
                values.append("nd_fecha", selectedDate);
                values.append("nd_url", urlAdd.value);
                dt.onSave(selectNota, values);
            } else {

                values.append("isEdit", dt.values ? "1" : "0");

                if (dt.values) {
                    values.append("url", dt.values.url);
                    values.append("codigo_nota", dt.values.codigo_nota.toString());

                    if (updateMedia) {
                        //Habilitada la opcion de actualizar contenido en el servidor (Imagenes y PDFs)

                        let redesimgAdd = document.getElementById("redesimg-add-nota") as HTMLInputElement;
                        let portadaImg = document.getElementById("principalimg-add-nota-destacada") as HTMLInputElement;
                        imgToBase64(portadaImg, (imgportada) => {
                            if (portadaImg.files![0]) {
                                values.append("ndia_portada_img_pie", portadaImg.files![0].name)
                            }
                            if (redesimgAdd.files?.length! > 0) {

                                let editorContent = JSON.stringify(editor.getContents()).replaceAll('\\\"', "///ack").replaceAll("'", "///dac"); //Importante para codificacion de caracteres especiales

                                values.append("ndia_titulo", tituloAdd.value);
                                values.append("ndia_descripcion", descripcionAdd.value);
                                values.append("ndia_fecha", selectedDate);
                                values.append("ndia_redes_img", redesimgAdd.files![0]);
                                values.append("ndia_portada_img", imgportada);
                                values.append("ndia_editor_content", editorContent);
                                dt.onSave(selectNota, values);
                            } else {
                                toast.error("Porfavor, rellena todos los campos");
                            }
                        });
                    } else {
                        /**
                         * ndia_portada_img_pie, ndia_portada_img, ndia_redes_img ya no son enviados 
                         */

                        let editorContent = {} as any;

                        try{
                            editorContent = JSON.stringify(editor.getContents()).replaceAll('\\\"', "///ack").replaceAll("'", "///dac"); //Importante para codificacion de caracteres especiales
                        }catch(err){
                            editorContent = dt.values.editor_content;
                        }

                        values.append("ndia_titulo", tituloAdd.value);
                        values.append("ndia_descripcion", descripcionAdd.value);
                        values.append("ndia_fecha", selectedDate);
                        values.append("ndia_editor_content", editorContent);
                        dt.onSave(selectNota, values);
                    }
                } else {

                    let redesimgAdd = document.getElementById("redesimg-add-nota") as HTMLInputElement;
                    let portadaImg = document.getElementById("principalimg-add-nota-destacada") as HTMLInputElement;
                    imgToBase64(portadaImg, (imgportada) => {
                        if (portadaImg.files![0]) {
                            values.append("ndia_portada_img_pie", portadaImg.files![0].name)
                        }
                        if (redesimgAdd.files?.length! > 0) {

                            let editorContent = JSON.stringify(editor.getContents()).replaceAll('\\\"', "///ack").replaceAll("'", "///dac"); //Importante para codificacion de caracteres especiales

                            values.append("ndia_titulo", tituloAdd.value);
                            values.append("ndia_descripcion", descripcionAdd.value);
                            values.append("ndia_fecha", selectedDate);
                            values.append("ndia_redes_img", redesimgAdd.files![0]);
                            values.append("ndia_portada_img", imgportada);
                            values.append("ndia_editor_content", editorContent);
                            dt.onSave(selectNota, values);
                        } else {
                            toast.error("Porfavor, rellena todos los campos");
                        }
                    });
                }
            }


        }} onCancel={dt.onCancel} />
    </>
}

export let Delete = (dt: { onCancel: () => any, onDelete: () => any }) => {
    return <>
        <ModalBody>
            <div className="delete-worker-component">
                <Label>
                    ¿Estás seguro de eliminarlo?
                </Label>
            </div>
        </ModalBody>

        <FooterAdminFormModal onSave={dt.onDelete} onCancel={dt.onCancel} otherText="Eliminar" />
    </>
}

export let Confirm = (dt: { onCancel: () => any, onConfirm: () => any }) => {
    return <>
        <ModalBody>
            <div className="delete-worker-component">
                <Label>
                    ¿Estás seguro de confirmarlo? No se podrá modificar nuevamente.
                </Label>
            </div>
        </ModalBody>

        <FooterAdminFormModal onSave={dt.onConfirm} onCancel={dt.onCancel} otherText="Confirmar" />
    </>
}
export let UpdatePass = (dt: { onCancel: () => any, onConfirm: (ant: string, nuev: string) => any }) => {
    return <>
        <ModalBody>
            <div className="delete-worker-component">
                <FormGroup>
                    <Label>Anterior contraseña</Label>
                    <Input type="password" id="pass-anterior" placeholder="Anterior contraseña" />
                </FormGroup>
                <FormGroup>
                    <Label>Nueva contraseña</Label>
                    <Input type="password" id="pass-nueva" placeholder="Nueva contraseña" />
                </FormGroup>
            </div>
        </ModalBody>

        <FooterAdminFormModal onSave={() => {
            let ant = document.getElementById("pass-anterior") as HTMLInputElement;
            let nueva = document.getElementById("pass-nueva") as HTMLInputElement;

            dt.onConfirm(ant.value, nueva.value);
        }} onCancel={dt.onCancel} otherText="Cambiar contraseña" />
    </>
}

let FooterAdminFormModal = (dt: { onSave: () => any, onCancel: () => any, otherText?: string }) => {
    return <ModalFooter className='d-flex flex-row justify-content-end'>
        <Button onClick={() => {
            dt.onCancel();
        }}>
            Cancelar
        </Button>
        <Button onClick={() => {
            dt.onSave();
        }} color='primary'>
            {dt.otherText ? dt.otherText : "Guardar"}
        </Button>
    </ModalFooter>
}

import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { CreateAlertsURL, CreateEdicionesURL, CreateRankingsURL } from "../components/comps";
import { errorDBName, notaDiaType, post, requestedDBName, usersType, userType } from "../theme/variables";

/*
        ERROR MESSAGE
*/

const errorMessage = "Ha ocurrido un error, porfavor intenta reducir el tamaño de algunos ficheros o contenido para subir.";

export let errorOnRequest = (err: any) => {
    /*
        La forma de los errores en memoria es:
        [
            {fecha: string, error: {}}
            , ...
        ]
    */

    try {
        let error = err.toJSON();
        let errorsBank: string | null = localStorage.getItem(errorDBName);

        let saveOnDB = (elem: string) => {
            localStorage.setItem(errorDBName, elem);
        }

        if (errorsBank) {
            let actualDate = new Date();
            let dep: { fecha: string, error: object }[] = JSON.parse(errorsBank);
            dep.push({ fecha: actualDate.toString(), error: error });

            let stringed = JSON.stringify(dep);

            saveOnDB(stringed);
        } else {
            let actualDate = new Date();
            let dep: { fecha: string, error: object }[] = [];
            dep.push({ fecha: actualDate.toString(), error: error });

            let stringed = JSON.stringify(dep);

            saveOnDB(stringed);
        }
    } catch (e) {
        //console.error(e)
    }

}

export let allRequestReport = (el: any) => {
    /*
        La forma de los reportes en memoria son:
        [
            {fecha: string, request: {}}
            , ...
        ]
    */

    let request = el;
    let errorsBank: string | null = localStorage.getItem(requestedDBName);

    let saveOnDB = (elem: string) => {
        try{
            localStorage.setItem(requestedDBName, elem);
        }catch(err){
            //No se ha podido guardar el error
        }
    }

    if (errorsBank) {
        let actualDate = new Date();
        let dep: { fecha: string, request: object }[] = JSON.parse(errorsBank);
        dep.push({ fecha: actualDate.toString(), request: request });

        let stringed = JSON.stringify(dep);

        saveOnDB(stringed);
    } else {
        let actualDate = new Date();
        let dep: { fecha: string, request: object }[] = [];
        dep.push({ fecha: actualDate.toString(), request: request });

        let stringed = JSON.stringify(dep);

        saveOnDB(stringed);
    }
}

export let refreshWorkers = (admin: userType, onSuccess: (workers: usersType) => any, onError: () => any) => {
    let form = new FormData();

    form.append("clave", "obtain-workers");
    form.append("user", admin.email);
    form.append("pass", admin.pass);

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean, workers: usersType };

        if (res.result) {
            onSuccess(res.workers);
            toast.success("Usuarios actualizados correctamente");

        } else {
            onError();
            toast.error(errorMessage)
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });
}



export let addWorker = (admin: userType, worker: userType, onSuccess: () => any, onError: () => any) => {

    let form = new FormData();

    form.append("clave", "add-worker");
    form.append("user", admin.email);
    form.append("pass", admin.pass);
    form.append("worker", JSON.stringify(worker));

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean };

        if (res.result) {
            onSuccess();
            toast.success("Nuevo usuario creado correctamente");

        } else {
            onError();
            toast.error(errorMessage)
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });

}

export let editWorker = (admin: userType, worker: userType, onSuccess: () => any, onError: () => any) => {

    let form = new FormData();

    form.append("clave", "edit-worker");
    form.append("user", admin.email);
    form.append("pass", admin.pass);
    form.append("worker", JSON.stringify(worker));

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean };

        if (res.result) {
            onSuccess();
            toast.success("Usuario editado correctamente");

        } else {
            onError();
            toast.error(errorMessage)
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });

}

export let deleteWorker = (admin: userType, worker: userType, onSuccess: () => any, onError: () => any) => {

    let form = new FormData();

    form.append("clave", "delete-worker");
    form.append("user", admin.email);
    form.append("pass", admin.pass);
    form.append("worker", worker.user_code.toString());

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean };
        if (res.result) {
            onSuccess();
            toast.success("Usuario eliminado correctamente");

        } else {
            onError();
            toast.error(errorMessage)
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });

}

export let addNota = (admin: userType, action: "nota-dia" | "nota-destacada", notaData: FormData, onSuccess: (url: any) => any, onError: () => any, onProgress: (percent: number) => any) => {

    notaData.append("clave", action === "nota-destacada" ? "add-nota-destacada" : "add-nota-dia");
    notaData.append("user", admin.email);
    notaData.append("pass", admin.pass);
    notaData.append("user_code", admin.user_code.toString());

    toast.info("Se esta subiendo la nota, una vez concluido la ventana se cerrará automaticamente");

    post("admin.php", notaData, {
        onUploadProgress: (ev: any) => {
            let percentLoaded = Math.round((ev.loaded * 100) / ev.total);
            onProgress(percentLoaded);
        }
    }).then((r) => {
        let res = r as { result: boolean, exceptions: string, details: { url: string } };

        if (res.result) {
            onSuccess(<>
                <CreateAlertsURL url={action === "nota-dia" ? res.details.url : notaData.get("nd_url") as string} />
            </>);
            let text: string = (action === "nota-dia" ? "Nueva nota" : "Nueva nota destacada") + " agregada correctamente";
            toast.success(text);
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });

}
export let addEdiciones = (admin: userType, action: "ediciones" | "informes" | "separatas", notaData: FormData, onSuccess: (url: any) => any, onError: () => any, onProgress: (percent: number) => any) => {

    notaData.append("clave", "add-ed_inf_sep");
    notaData.append("user", admin.email);
    notaData.append("pass", admin.pass);
    notaData.append("user_code", admin.user_code.toString());

    let text: string = "Se esta creando".concat(action === "ediciones" ? " la edición" : (action === "informes" ? " el informe" : " la separata"));

    toast.info(text);

    post("admin.php", notaData, {
        onUploadProgress: (ev: any) => {
            let percentLoaded = Math.round((ev.loaded * 100) / ev.total);
            onProgress(percentLoaded);
        }
    }).then((r) => {
        let res = r as { result: boolean, exceptions: string, details: { url: string } };

        if (res.result) {
            onSuccess(<>
                <CreateEdicionesURL url={res.details.url} />
            </>);
            toast.success("Creado correctamente");
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });

}

export let addRankings = (admin: userType, action: "empresas" | "financiero", notaData: FormData, onSuccess: (url: any) => any, onError: () => any, onProgress: (percent: number) => any) => {

    notaData.append("clave", "add-rk");
    notaData.append("user", admin.email);
    notaData.append("pass", admin.pass);
    notaData.append("user_code", admin.user_code.toString());

    let text: string = "Se esta creando".concat(action === "empresas" ? " la edición empresas" : (action === "financiero" ? " la edición financiero" : ""));

    toast.info(text);

    post("admin.php", notaData, {
        onUploadProgress: (ev: any) => {
            let percentLoaded = Math.round((ev.loaded * 100) / ev.total);
            onProgress(percentLoaded);
        }
    }).then((r) => {
        let res = r as { result: boolean, exceptions: string, details: { url: string } };

        if (res.result) {
            onSuccess(<>
                <CreateRankingsURL url={res.details.url} />
            </>);
            toast.success("Creado correctamente");
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });

}

export let confirmNota = (user: userType, nota: notaDiaType, onSuccess: () => any, onError: () => any) => {
    let form = new FormData();
    form.append("clave", "confirm-nota");
    form.append("user", user.email);
    form.append("pass", user.pass);
    form.append("codigo_nota", nota.codigo_nota.toString());
    form.append("user_code", user.user_code.toString());

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean, exceptions: string };

        if (res.result) {
            onSuccess();
            toast.success("Confirmado correctamente");
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });
}

export let confirmEdicion = (user: userType, nota: any, onSuccess: () => any, onError: () => any) => {
    let form = new FormData();
    form.append("clave", "confirm-edicion");
    form.append("user", user.email);
    form.append("pass", user.pass);
    form.append("codigo_elemento", nota.codigo_elemento.toString());
    form.append("user_code", user.user_code.toString());

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean, exceptions: string };

        if (res.result) {
            onSuccess();
            toast.success("Confirmado correctamente");
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });
}

export let confirmRanking = (user: userType, nota: any, onSuccess: () => any, onError: () => any) => {
    let form = new FormData();
    form.append("clave", "confirm-ranking");
    form.append("user", user.email);
    form.append("pass", user.pass);
    form.append("codigo_elemento", nota.codigo_elemento.toString());
    form.append("user_code", user.user_code.toString());

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean, exceptions: string };

        if (res.result) {
            onSuccess();
            toast.success("Confirmado correctamente");
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });
}

export let deleteNota = (user: userType, nota: notaDiaType, onSuccess: () => any, onError: () => any) => {
    let form = new FormData();
    form.append("clave", "delete-nota");
    form.append("user", user.email);
    form.append("pass", user.pass);
    form.append("codigo_nota", nota.codigo_nota.toString());
    form.append("user_code", user.user_code.toString());

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean, exceptions: string };

        if (res.result) {
            onSuccess();
            toast.success("Eliminado correctamente");
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });
}
export let deleteEdicion = (user: userType, nota: any, onSuccess: () => any, onError: () => any) => {
    let form = new FormData();
    form.append("clave", "delete-edicion");
    form.append("user", user.email);
    form.append("pass", user.pass);
    form.append("codigo_elemento", nota.codigo_elemento.toString());
    form.append("user_code", user.user_code.toString());

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean, exceptions: string };

        if (res.result) {
            onSuccess();
            toast.success("Eliminado correctamente");
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });
}

export let deleteRanking = (user: userType, nota: any, onSuccess: () => any, onError: () => any) => {
    let form = new FormData();
    form.append("clave", "delete-ranking");
    form.append("user", user.email);
    form.append("pass", user.pass);
    form.append("codigo_elemento", nota.codigo_elemento.toString());
    form.append("user_code", user.user_code.toString());

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean, exceptions: string };

        if (res.result) {
            onSuccess();
            toast.success("Eliminado correctamente");
        } else {
            onError();
            toast.error(errorMessage);
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });
}


export let updatePass = (user: userType, ant: string, nuev: string, onSuccess: () => any, onError: () => any) => {
    let form = new FormData();
    form.append("clave", "update-pass");
    form.append("user", user.email);
    form.append("pass", user.pass);
    form.append("pass_ant", ant);
    form.append("pass_nueva", nuev);

    post("admin.php", form, {}).then((r) => {
        let res = r as { result: boolean, exceptions: string, newValue: string };

        if (res.result) {
            let an = JSON.parse(localStorage.getItem("user-auth")!);
            an["pass"] = res.newValue;

            localStorage.setItem("user-auth", JSON.stringify(an));
            onSuccess();
            toast.success("Cambiaste tu contraseña correctamente");
        } else {
            onError();
            toast.error(("Ocurrio un error: " + res.exceptions));
        }
    }).catch(err => {
        errorOnRequest(err);
        onError();
        toast.error("Ha ocurrido un error: " + err)
    });
}
import "../theme/styles/components/Title.scss";

const NativeTitle = (props: {
    id?: string,
    className?: string,
    children: any
}) => {
    return <div className={"native-title-component " + (props.className ? props.className : "")} id={props.id}>
        <span>{props.children}</span>
        <div></div>
    </div>
}

export default NativeTitle;
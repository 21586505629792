import { saveAs } from "file-saver";
import Quill from "quill";
import "quill/dist/quill.snow.css";

import { useState, useEffect, CSSProperties, useRef, useLayoutEffect } from "react";
import { toast } from "react-toastify";
import { Alert, Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Tooltip } from "reactstrap";
import { carruselType, DestacadaSelectable, EditIcon, errorDBName, post, RefreshIcon, requestedDBName, userType } from "../theme/variables";

import "./scss/comps.scss";

export let TextEditor = (props: { onObtain: (edit: Quill) => any, style?: any, defaultValue?: string }) => {
    const templateQuill = new Object as Quill;

    const [user, setUser] = useState({ rol: "", confirmed: false, user_code: -1 });
    const [editor, setEditor] = useState(templateQuill);

    const colors = ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'];
    const customColors = ["#7099d1", "#2A477B", "#2D2D34", "#75bb25"];

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['link', 'image'],
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [...colors, ...customColors] }, { 'background': [...colors, ...customColors] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    useEffect(() => {
        if (editor !== templateQuill) {
            if (props.defaultValue !== undefined) {
                let content_editor = props.defaultValue.replace('\n', '').replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
                try {
                    editor.setContents(JSON.parse(content_editor));
                } catch (ev: any) {
                    editor.setText("");
                }
            }
        }
    }, [editor])

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user-auth")!));

        setEditor(new Quill('#editor', {
            modules: {
                toolbar: toolbarOptions
            },
            theme: 'snow',
        }))

    }, []);

    //Obtener: JSON.stringify(editor.getContents())
    //Set: editor.setContents(JSON.parse(''))

    return <div onBlur={() => {
        props.onObtain(editor);
    }}>

        <div className='text-editor-component'>
            <div id="toolbar-container">

            </div>
            <div id="editor" style={props.style ? props.style : ""}>

            </div>

            <Row className="mt-2">
                <Col xs="2" className="pr-0">
                    <Button className="button-restore-editor w-100" onClick={() => {
                        let saved = localStorage.getItem("nota-dia-editor-save");
                        if (saved) {
                            let res = window.confirm("Se encontró una copia de seguridad, ¿Desea sobreescribir su trabajo actual?");
                            if (res) {
                                editor.setContents(JSON.parse(saved));
                            }
                        } else {
                            toast.error("No se ha encontrado ninguna copia de seguridad en tu disco");
                        }
                    }}>
                        {RefreshIcon}
                    </Button>
                </Col>
                <Col xs="10" className="pl-0">
                    <Button color="primary" className="btn-primary button-save-editor w-100"
                        onClick={() => {
                            props.onObtain(editor);
                            try {
                                localStorage.setItem("nota-dia-editor-save", JSON.stringify(editor.getContents()));
                                toast.success("Se ha guardado tu progreso");
                            } catch (ev) {
                                toast.error("No se ha podido guardar una copia de seguridad, espacio dedicado lleno");
                            }
                        }}
                    >Guardar progreso</Button>
                </Col>
            </Row>
        </div>
    </div>
}

export let InputEditable = (dt: {
    defaultValue: string,
    placeholder: string,
    textType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6",
    onSave: (input: HTMLInputElement) => any
}) => {
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        let input = document.getElementById(dt.placeholder + "-input-edit") as HTMLInputElement;
        if (edit) {
            input.focus();
        }
    }, [edit]);

    return <div className="input-editable-component d-flex flex-row align-items-center my-1">
        <Input placeholder={dt.placeholder}
            defaultValue={dt.defaultValue}
            id={dt.placeholder + "-input-edit"}
            alt={dt.defaultValue}
            contentEditable={edit}
            className={dt.textType}
        />

        {edit ?
            <Button onClick={() => {
                dt.onSave(document.getElementById(dt.placeholder + "-input-edit") as HTMLInputElement);
                setEdit(false);
            }}>
                Guardar
            </Button>
            :
            <div className="icon-input-editable d-flex align-items-center" onClick={() => {
                setEdit(true);
            }}>
                {EditIcon}
            </div>
        }

        <Tooltip
            flip
            target={dt.placeholder + "-input-edit"}
            toggle={function noRefCheck() { }}
        >
            {dt.defaultValue}
        </Tooltip>
    </div>
}

export let LoadProgress = (props: { percent: number }) => {
    return <div className="loaded-progress">
        <span>{props.percent}%</span>
    </div>
}

/*      CREATE ALERTS       */
export let CreateAlertsURL = (props: { url: string, style?: CSSProperties }) => {
    return <Row className="create-url" style={props.style}>
        <hr className="w-100 mt-3" />
        <Label className="text-center mt-1 mb-3">Nota nueva</Label>
        <span className="col-12"><strong>URL:</strong> https://nuevaeconomia.com.bo/nota.php?url={props.url}</span>
        <Col xs="4">
            <a className="btn-web" href={"https://nuevaeconomia.com.bo/nota.php?url=" + props.url} target="_blank">
                <img src="https://img.icons8.com/ios/50/ffffff/internet--v1.png" />
                WEB
            </a>
        </Col>
        <Col xs="4">
            <a className="btn-wpp" href={"https://api.whatsapp.com/send?text=https%3A%2F%2Fnuevaeconomia.com.bo%2Fnota.php?url=" + props.url} target="_blank">
                <img src="https://img.icons8.com/ios-glyphs/30/ffffff/whatsapp.png" />
                WhatsApp
            </a>
        </Col>
        <Col xs="4">
            <a className="btn-fb" href={"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fnuevaeconomia.com.bo%2Fnota.php?url=" + props.url} target="_blank">

                <img src="https://img.icons8.com/ios/50/ffffff/facebook.png" />
                Facebook
            </a>
        </Col>
    </Row>
}

export let CreateRankingsURL = (props: { url: string, style?: CSSProperties }) => {
    return <Row className="create-url" style={props.style}>
        <hr className="w-100 mt-3" />
        <Label className="text-center mt-1 mb-3">Nuevo Ranking</Label>
        <span className="col-12"><strong>URL:</strong> https://nuevaeconomia.com.bo/ranking.php?url={props.url}</span>
        <Col xs="4">
            <a className="btn-web" href={"https://nuevaeconomia.com.bo/ranking.php?url=" + props.url} target="_blank">
                <img src="https://img.icons8.com/ios/50/ffffff/internet--v1.png" />
                WEB
            </a>
        </Col>
        <Col xs="4">
            <a className="btn-wpp" href={"https://api.whatsapp.com/send?text=https%3A%2F%2Fnuevaeconomia.com.bo%2Franking.php?url=" + props.url} target="_blank">
                <img src="https://img.icons8.com/ios-glyphs/30/ffffff/whatsapp.png" />
                WhatsApp
            </a>
        </Col>
        <Col xs="4">
            <a className="btn-fb" href={"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fnuevaeconomia.com.bo%2Franking.php?url=" + props.url} target="_blank">
                <img src="https://img.icons8.com/ios/50/ffffff/facebook.png" />
                Facebook
            </a>
        </Col>
    </Row>
}

export let CreateEdicionesURL = (props: { url: string, style?: CSSProperties }) => {
    return <Row className="create-url" style={props.style}>
        <hr className="w-100 mt-3" />
        <Label className="text-center mt-1 mb-3">Nueva Edición</Label>
        <span className="col-12"><strong>URL:</strong> https://nuevaeconomia.com.bo/edicion.php?url={props.url}</span>
        <Col xs="4">
            <a className="btn-web" href={"https://nuevaeconomia.com.bo/edicion.php?url=" + props.url} target="_blank">
                <img src="https://img.icons8.com/ios/50/ffffff/internet--v1.png" />
                WEB
            </a>
        </Col>
        <Col xs="4">
            <a className="btn-wpp" href={"https://api.whatsapp.com/send?text=https%3A%2F%2Fnuevaeconomia.com.bo%2Fedicion.php?url=" + props.url} target="_blank">
                <img src="https://img.icons8.com/ios-glyphs/30/ffffff/whatsapp.png" />
                WhatsApp
            </a>
        </Col>
        <Col xs="4">
            <a className="btn-fb" href={"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fnuevaeconomia.com.bo%2Fedicion.php?url=" + props.url} target="_blank">

                <img src="https://img.icons8.com/ios/50/ffffff/facebook.png" />
                Facebook
            </a>
        </Col>
    </Row>
}

export let Title = (props: { text: string }) => {
    return <Label className="title-component">
        {props.text}
    </Label>
}

export let Subtitle = (props: { text: string }) => {
    return <span className="subtitle-component">
        {props.text}
    </span>
}

export let GenerateErrorsReport = (props: { className: string }) => {

    let generarReporte = () => {
        let errors: string | null = localStorage.getItem(errorDBName);

        let blob = new Blob(["No existen errores registrados"], { type: "text/plain;charset=utf-8" });

        if (errors) {
            blob = new Blob([errors], { type: "text/plain;charset=utf-8" });
        }

        saveAs(blob, "errorbank_report-" + (new Date()).toString() + ".json");

        let reqs: string | null = localStorage.getItem(requestedDBName);

        blob = new Blob(["No existen request's registrados"], { type: "text/plain;charset=utf-8" });

        if (reqs) {
            blob = new Blob([reqs], { type: "text/plain;charset=utf-8" });
        }

        saveAs(blob, "requests_report-" + (new Date()).toString() + ".json");
    }

    return <div className={"w-100 d-flex flex-column justify-content-center align-items-center p-0 m-0 " + props.className}>
        <button className="as-a" onClick={() => generarReporte()}>Generar reportes de errores</button>
    </div>
}

export let SelectablePosNotaDestacada = (props: { default: number, open: boolean, target: HTMLElement, onSelect: (pos: number) => any }) => {
    let top = "0";
    let left = "0";
    let width = "0";
    let height = "0";
    if (props.open) {
        top = (props.target.offsetTop + 38).toString().concat("px");
        left = props.target.offsetLeft.toString().concat("px");
        width = props.target.offsetWidth.toString().concat("px");
        height = (props.target.offsetWidth * 0.6666).toString().concat("px");

        let bg = document.getElementById("bg") as HTMLElement;
        let uno = document.getElementById("_1") as HTMLElement;
        let dos = document.getElementById("_2") as HTMLElement;
        let tres = document.getElementById("_3") as HTMLElement;
        let cua = document.getElementById("_4") as HTMLElement;
        let cin = document.getElementById("_5") as HTMLElement;

        bg.style.fill = "white";

        uno.classList.remove("selected");
        dos.classList.remove("selected");
        tres.classList.remove("selected");
        cua.classList.remove("selected");
        cin.classList.remove("selected");

        let select = document.getElementById("_" + props.default) as HTMLElement;
        select.classList.add("selected");
    }
    return <div id="selectable-pos-notadestacada" className="selectable-pos-notadestacada" style={{ top: top, left: left, display: props.open ? "block" : "none", width: width, height: height }} onClick={(ev: any) => {
        props.onSelect(parseInt(ev.target.id.replaceAll("_", "")));
    }}>
        {DestacadaSelectable}
    </div>
}

/*      CARRUSEL CARD       */
export let CarruselCard = (props: { carrusel: carruselType, user: userType, onEdit: (newData: carruselType) => any }) => {
    const [openModal, setOpenModal] = useState({ open: false, action: "edit" as "edit" | "change" });
    const [enableSearch, setEnableSearch] = useState(false);
    const [carruselNew, setCarruselNew] = useState(new Object as carruselType);

    const titleRef = useRef(new Object as HTMLInputElement);
    const urlRef = useRef(new Object as HTMLInputElement);
    const imageRef = useRef(new Object as HTMLInputElement);
    const nroRef = useRef(new Object as HTMLInputElement);

    const titleChangeRef = useRef(new Object as HTMLInputElement);
    const imageChangeRef = useRef(new Object as HTMLInputElement);
    const urlChangeORef = useRef(new Object as HTMLInputElement);

    const urlChangeRef = useRef(new Object as HTMLInputElement);

    let updateCarruselItem = () => {
        let form = new FormData();
        form.append("clave", "update-carrusel-item");
        form.append("email", props.user.email);
        form.append("pass", props.user.pass);

        if (openModal.action === "edit") {
            form.append("title", titleRef.current.value);
            form.append("image", imageRef.current.value);
            form.append("nro", props.carrusel.nro.toString());
            form.append("url", props.carrusel.url);
        } else {
            form.append("title", titleChangeRef.current.value);
            form.append("image", imageChangeRef.current.value);
            form.append("nro", props.carrusel.nro.toString());
            form.append("url", urlChangeORef.current.value.split("?url=")[1].split("&fbclid")[0]);
        }

        post("admin.php", form, {}).then(r => {
            let res = r as { result: boolean, exceptions: any };

            if (res.result == true) {
                let newValues: carruselType = {} as any;

                if (openModal.action === "edit") {
                    try {
                        newValues["titulo"] = titleRef.current.value;
                        newValues["image"] = imageRef.current.value;
                        newValues["nro"] = props.carrusel.nro;
                        newValues["url"] = props.carrusel.url;
                    } catch (e) { }
                } else {
                    try {
                        newValues["titulo"] = titleChangeRef.current.value;
                        newValues["image"] = imageChangeRef.current.value;
                        newValues["nro"] = props.carrusel.nro;
                        newValues["url"] = urlChangeORef.current.value.split("?url=")[1].split("&fbclid")[0];
                    } catch (e) {

                    }

                }
                setOpenModal({ open: false, action: openModal.action });
                props.onEdit(newValues)
            } else {
                setOpenModal({ open: false, action: openModal.action });
                toast.error("Ha ocurrido un error, porfavor intenta iniciar sesion nuevamente")
            }
        }).catch((err) => {
            setOpenModal({ open: false, action: openModal.action });
            toast.error("Ha ocurrido un error, porfavor intenta nuevamente más tarde");
        })
    }

    let searchByUrl = (event: any) => {
        let form = new FormData();

        form.append("clave", "search-note-by-url");
        form.append("url", urlChangeRef.current.value.split("?url=")[1].split("&fbclid")[0]);

        event.target.innerText = "Buscando..."
        post("admin.php", form, {}).then(r => {
            let res = r as { finded: boolean, value: carruselType };
            if (res.finded) {
                setCarruselNew(res.value);
                event.target.innerText = "";
                event.target.style.display = "none";

                imageChangeRef.current.value = "https://administrador.nuevaeconomia.com.bo/api/img/portada_img/" + res.value.url + ".png";
                titleChangeRef.current.value = res.value.titulo;
                urlChangeORef.current.value = "https://nuevaeconomia.com.bo/nota.php?url=" + res.value.url;

            } else {
                event.target.innerText = "Buscar";
                toast.error("La nota no se ha encontrado, porfavor revise el enlace");
            }
        }).catch(() => {
            event.target.innerText = "Buscar";
            toast.error("Ha ocurrido un error buscando la nota, porfavor intenta nuevamente más tarde");
        })
    }

    return <div className="carrusel-card">
        <Modal isOpen={openModal.open} centered>
            <ModalHeader>
                {openModal.action === "edit" ? props.carrusel.titulo : "Cambiar carrusel " + props.carrusel.nro + " por: "}
            </ModalHeader>
            <ModalBody>
                {openModal.action === "edit" ?
                    <>
                        <Label>
                            Titulo
                        </Label>
                        <Input className="mb-3" placeholder="Titulo" defaultValue={props.carrusel.titulo} innerRef={titleRef} />

                        <Label>
                            Imagen
                        </Label>
                        <Input className="mb-3" placeholder="Imagen" defaultValue={props.carrusel.image} type="text" innerRef={imageRef} />

                        <Label>
                            Nro
                        </Label>
                        <Input className="mb-3" placeholder="Nro" defaultValue={props.carrusel.nro} innerRef={nroRef} type="number" disabled />
                        <Label>
                            URL
                        </Label>
                        <Input className="mb-3" placeholder="URL" defaultValue={"https://nuevaeconomia.com.bo/nota.php?url=" + props.carrusel.url} disabled innerRef={urlRef} />
                    </>
                    :
                    <>
                        <Label>
                            Buscar por URL de Nota
                        </Label>
                        <div className="mb-3 d-flex flex-row flex-nowrap align-items-center">
                            <Input style={{ marginRight: ".5rem" }} placeholder="Ingresa la URL" innerRef={urlChangeRef} onInput={(ev: any) => {
                                if (ev.target.value.length > 0) {
                                    setEnableSearch(true);
                                } else {
                                    setEnableSearch(false);
                                }
                            }} />
                            <Button color="primary" onClick={searchByUrl} disabled={!enableSearch}>Buscar</Button>
                        </div>

                        <hr />

                        <Label>
                            Titulo
                        </Label>
                        <Input className="mb-3" placeholder="Titulo" innerRef={titleChangeRef} />

                        <Label>
                            Imagen
                        </Label>
                        <Input className="mb-3" placeholder="Imagen" type="text" innerRef={imageChangeRef} />

                        <Label>
                            Nro
                        </Label>
                        <Input className="mb-3" placeholder="Nro" defaultValue={props.carrusel.nro} innerRef={nroRef} type="number" disabled />

                        <Label>
                            URL
                        </Label>
                        <Input className="mb-3" placeholder="URL" innerRef={urlChangeORef} />

                    </>
                }
            </ModalBody>
            <ModalFooter className="d-flex flex-row justify-content-end">
                <Button color="secondary" onClick={() => { setOpenModal({ open: false, action: openModal.action }); }}>Cancelar</Button>
                <Button color="primary" onClick={updateCarruselItem}>Guardar</Button>
            </ModalFooter>
        </Modal>

        <div className="card-carrusel-element">
            <img src={props.carrusel.image} onError={(ev: any) => {
                if (props.carrusel.image.includes("portada_img")) {
                    ev.target.src = props.carrusel.image.replaceAll("portada_img", "redes_img");
                } else {
                    ev.target.src = props.carrusel.image.replaceAll("redes_img", "portada_img");
                }
            }} />

            <Label>
                {props.carrusel.titulo}
            </Label>
        </div>

        <div className="card-carrusel-buttons">
            <Button color="secondary" onClick={() => { setOpenModal({ open: true, action: "edit" }) }}>Editar</Button>
            <Button color="primary" onClick={() => { setOpenModal({ open: true, action: "change" }) }}>Cambiar</Button>
        </div>
    </div>
}
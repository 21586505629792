import { CSSProperties, MutableRefObject, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../common/routes";
import "../theme/styles/components/Header.scss";

const Header = (props: {
    openShoppingCart?: boolean
}) => {

    let location: {
        state: Object | null,
        pathname: string
    } = useLocation() as any;

    const HeaderRef = useRef(new Object as HTMLDivElement);

    return <div className="header-component" ref={HeaderRef}>
        <Link to={routes.admin} className="img-logo-header"><img src="https://nuevaeconomia.com.bo/img/ne/logoNE_2.png" alt="Logo GNE" /></Link>

        <div className="routes-header">
            <Link to={routes.admin}
                className={location.pathname.includes(routes.admin) ? "active" : ""}>
                Menú principal
            </Link>
            <Link to={routes.nuevaeco}
                className={location.pathname.includes(routes.nuevaeco) ? "active" : ""}>
                Nueva Economía
            </Link>
            <Link to={routes.pagos}
                className={location.pathname.includes(routes.pagos) ? "active" : ""}>
                Pagos
            </Link>
            <Link to={routes.users}
                className={location.pathname.includes(routes.users) ? "active" : ""}>
                Usuarios
            </Link>
            <Link to={routes.profile}
                className={location.pathname.includes(routes.profile) ? "active" : ""}>
                Tu perfil
            </Link>

        </div>

    </div>
}
export default Header;
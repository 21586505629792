
export type MagazzinePlan = {
    plan_code: number,
    name: string,
    duration: number,
    price: number,  //BOB
    winnings: Winning[],
    habs?: string[],
    description?: string,
    active: 1 | 0
}

export type Course = {
    course_code: number,
    title: string,
    description: string,
    type: "virtual" | "face-to-face" | "train",
    details: Winning[],
    cover_image: string,
    price: number, //BOB
    teacher: Teacher,
    start_date: string,
    end_date: string,
    active?: 1 | 0,
    location: string
}

export type Winning = {
    icon: any,
    text: string
}

export type Teacher = {
    name: string,
    description?: string,
    profession?: string,
    image: string
}


/*      AMAzON GALELERT     */

export type Product = {
    product_code: string | number,
    name: string,
    description: string,
    winnings: Winning[],
    provider: {
        enterprise: string,
        comunity: string,
        others: string,
        location: string,
        locationInfo: string,
        sanitaryCode: string    //If has not this use N/N
    },
    category: string,
    images: string[],
    price: number,
    active?: 1 | 0
} 

export const Categories: { title: string, code: string }[] = [ //p: productos, f: frutas
    {
        title: "Frutas amazónicas",
        code: "f-amz"
    },
    {
        title: "Ecoturismo",
        code: "p-eco-t"
    },
    {
        title: "Cacao",
        code: "f-cacao"
    },
    {
        title: "Cuidado de fauna",
        code: "p-pre-fn"
    },
    {
        title: "Café",
        code: "f-cafe"
    },
    {
        title: "Cosméticos",
        code: "p-cosmt"
    },
    {
        title: "Cuidado de flora y fuentes de agua",
        code: "p-pre-fl-ag"
    },
    {
        title: "Plantas medicinales",
        code: "p-med"
    },
    {
        title: "Materiales de contrucción",
        code: "p-const"
    },
    {
        title: "Manejo de abejas nativas",
        code: "p-abj"
    },
    {
        title: "Derivados de frutos amázonicos",
        code: "p-drv-amz"
    },
    {
        title: "Mateial audiovisual",
        code: "p-maudv"
    },
];


/**
 * RESPONSE TYPE
 */

export type ResponseGeneral = {
    RESULT: boolean,
    VALUE: any,
    EXCEPTION_MESSAGE: string
}
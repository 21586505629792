import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Collapse } from "react-collapse";
import { toast } from "react-toastify";
import { Button, Input, Label, Row } from "reactstrap";
import { madePost } from "../common/post";
import { MagazzinePlan, ResponseGeneral, Winning } from "../common/types";
import { Catch } from "../common/variables";
import { clone } from "../functions/functions";

import "../theme/styles/components/MagazzineCard.scss";

export const MagazzineCards = (
    props: {
        cards: MagazzinePlan[],
        onCopy: (plan: MagazzinePlan) => any,
        isNew: boolean
    }
) => {
    return <div className="cards-magazzine">
        {
            props.isNew ?
                <MagazzinePlanCard onCopy={() => { }} plan={props.cards[props.cards.length - 1]} index={props.cards.length} isCopy={true} isNew={true} />
                :
                <></>

        }
        {
            props.cards.slice(0, -2).map((El: MagazzinePlan, index: number) => {
                return El.plan_code == -1 ? <MagazzinePlanCard onCopy={props.onCopy} plan={El} index={index} isNew={true} /> : <></>;
            })
        }
        {
            props.cards.map((El: MagazzinePlan, index: number) => {
                return El.plan_code != -1 ? <MagazzinePlanCard onCopy={props.onCopy} plan={El} index={index} isNew={false} /> : <></>;
            })
        }
    </div>
}

const MagazzinePlanCard = (props: {
    plan: MagazzinePlan,
    index: number,
    isNew: boolean,
    isCopy?: boolean,
    onCopy: (plan: MagazzinePlan) => any
}) => {
    const [plan, setPlan] = useState(props.plan as MagazzinePlan);
    const [isEditted, setIsEditted] = useState(props.isNew);

    const [viewHabs, setViewHabs] = useState(false);
    const [isEditting, setIsEditting] = useState("");

    const [isShow, setIsShow] = useState(false);;

    const nameRef = useRef({} as HTMLInputElement);
    const timeRef = useRef({} as HTMLInputElement);
    const priceRef = useRef({} as HTMLInputElement);
    const newWinningRef = useRef({} as HTMLInputElement);
    const newHabRef = useRef({} as HTMLInputElement);
    const descriptionRef = useRef({} as HTMLTextAreaElement);

    let durationPlan = () => {
        switch (plan.duration) {
            case 3: return "Trimestral";
            case 6: return "Semestral";
            case 12: return "Anual";
            case 24: return "Bianual";
            default: return (plan.duration > 1 ? plan.duration + " Meses" : plan.duration + " Mes");
        }
    }

    const deshabilitar = () => {
        if (plan.active == 1) {
            // eslint-disable-next-line no-restricted-globals
            let rr = confirm("Desea deshabilitar este plan? Se dejará de ver en el sitio web");
            if (rr) {
                madePost(
                    {
                        key: "manage-plan",
                        enable: 0,
                        plan_code: plan.plan_code
                    },
                    "AdminPagos.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        let p = clone(plan);

                        p.active = 0;

                        setPlan(p);
                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(Catch)
            }
        } else {
            // eslint-disable-next-line no-restricted-globals
            let rr = confirm("Desea habilitar este plan? Se volverá a ver en el sitio web");

            madePost(
                {
                    key: "manage-plan",
                    enable: 1,
                    plan_code: plan.plan_code
                },
                "AdminPagos.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    let p = clone(plan);

                    p.active = 1;

                    setPlan(p);
                } else {
                    toast.error(res.EXCEPTION_MESSAGE)
                }
            }).catch(Catch)
        }


    }

    const savePlan = () => {
        toast.info("Actualizando plan");
        madePost(
            {
                key: props.isNew ? "create-plan" : "modify-plan",
                plan_code: props.isNew ? -1 : plan.plan_code,
                name: plan.name,
                duration: plan.duration,
                price: plan.price,
                winnings: JSON.stringify(plan.winnings),
                habs: JSON.stringify(plan.habs),
                description: descriptionRef.current.value,
                isCopy: props.isCopy ? 1 : 0
            },
            "AdminPagos.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("Plan modificado correctamente");

                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            } else {
                toast.error(res.EXCEPTION_MESSAGE)
            }
        }).catch(Catch)
    }

    const generateHab = (hab: string) => {
        switch (hab) {
            case "virtual": return "Contenido digital";
            case "physical": return "Presentación física";
            case "extra": return "Contenido extra";
            case "email": return "Envío a su correo";
            case "newsletter": return "Inclusión al NewsLetter";
            default: return hab;
        }
    }

    if (isEditted) {
        return <div className={"card-magazzine-plan sides-min-x sides-min-y " + (props.isNew ? "new" : "")} key={"magazzine-card-" + props.index}>
            {
                isEditting.length > 0 && isEditting === "name" ?
                    <div className="edit-area">
                        <Input placeholder="Nombre de plan" type="text" innerRef={nameRef} />
                        <Button color="blue" onClick={() => {
                            let p = clone(plan)

                            p.name = nameRef.current.value.toUpperCase();
                            setPlan(p);
                            setIsEditting("");
                        }}>
                            Cambiar
                        </Button>
                    </div>
                    :
                    <h4 onClick={() => {
                        setIsEditting("name")
                    }}>{plan.name}</h4>
            }

            {
                isEditting.length > 0 && isEditting === "time" ?
                    <div className="edit-area">
                        <Input placeholder="Tiempo (en meses)" type="number" innerRef={timeRef} />
                        <Button color="blue" onClick={() => {
                            let p = clone(plan)

                            try {
                                p.duration = parseInt(timeRef.current.value);
                            } catch (err) {
                                p.duration = timeRef.current.value;
                            }
                            setPlan(p);
                            setIsEditting("");
                        }}>
                            Cambiar
                        </Button>
                    </div>
                    :
                    <p onClick={() => {
                        setIsEditting("time")
                    }}>{durationPlan()}</p>
            }

            {
                isEditting.length > 0 && isEditting === "price" ?
                    <div className="edit-area">
                        <Input placeholder="Precio (en Bs.)" type="number" innerRef={priceRef} />
                        <Button color="blue" onClick={() => {
                            let p = clone(plan)

                            try {
                                p.price = parseInt(priceRef.current.value);
                            } catch (err) {
                                p.price = priceRef.current.value;
                            }
                            setPlan(p);
                            setIsEditting("");
                        }}>
                            Cambiar
                        </Button>
                    </div>
                    :
                    <h2 onClick={() => {
                        setIsEditting("price")
                    }}>{plan.price} <small>Bs./u</small></h2>
            }


            <div className="winning-magazzine-plans-container">
                {
                    plan.winnings.length > 0 ?
                        plan.winnings.map((winning: Winning, ii: number) => {
                            return <div className="winning-magazzine-plan">
                                <div>
                                    <i className={"bi bi-" + winning.icon}></i>
                                    <span>{winning.text}</span>
                                </div>
                                <i className="bi bi-x" onClick={() => {
                                    // eslint-disable-next-line no-restricted-globals
                                    let r = confirm("Está seguro de eliminar este elemento?");

                                    if (r) {
                                        let p: MagazzinePlan = clone(plan);

                                        p["winnings"] = p.winnings.filter((ee, index) => { return index !== ii });

                                        setPlan(p);
                                    }
                                }}></i>
                            </div>
                        }
                        ) :
                        <label>No puedes dejar esto vacio</label>
                }

                <div className="winning-magazzine-plan align-items-start">
                    <div>
                        <i className={"bi bi-" + "check-lg"}></i>
                    </div>
                    <div className="new-winning w-100">
                        <Input className="" placeholder="Nuevo texto (Vista usuario)" type="text" innerRef={newWinningRef} />

                        <Button className="align-self-end" color="blue" onClick={() => {
                            let p = clone(plan);

                            p.winnings.push({ icon: "check-lg", text: newWinningRef.current.value });
                            setPlan(p);

                            setTimeout(() => {
                                newWinningRef.current.value = "";
                            }, 200)
                        }}>Agregar</Button>
                    </div>
                </div>

                <div className="habs-disponible">
                    <div className="w-100 d-flex flex-row align-items-center gap-4">
                        <h4 className="my-0 block " >Contenido habilitado para el suscriptor</h4>
                        <Button color="blue" className="px-2 py-0" style={{ fontSize: ".8rem" }} onClick={() => {
                            setViewHabs(!viewHabs)
                        }}>{viewHabs ? <b className="bi bi-eye-slash text-white" ></b> : <b className="bi bi-eye text-white"></b>}</Button>
                    </div>
                    <Collapse isOpened={viewHabs}>
                        {
                            plan.habs!.length > 0 ?
                                plan.habs!.map((hab: string, ii: number) => {
                                    return <div className="hab">
                                        <Label  >
                                            {
                                                generateHab(hab)
                                            }
                                        </Label>

                                        <i className="bi bi-x" onClick={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            let rr = confirm("Está seguro de eliminar este elemento?");

                                            if (rr) {
                                                let p: MagazzinePlan = clone(plan);

                                                p["habs"] = p.habs!.filter((ee, index) => { return index !== ii });

                                                setPlan(p);
                                            }
                                        }}></i>

                                    </div>
                                })
                                :
                                <label>No puedes dejar esto vacio</label>
                        }
                        <div className="hab mt-2 flex-column hab-default">
                            <small className="text-start">Agregar permisos por defecto</small>
                            <Input type="select" className="" defaultValue="other" onChange={(ev: any) => {
                                let p = clone(plan);

                                p.habs!.push(ev.target.value);

                                setPlan(p);

                                ev.target.value = "other";
                            }} >
                                {!plan.habs?.includes("virtual") && <option value="virtual" >Contenido virtual</option>}
                                {!plan.habs?.includes("physical") && <option value="physical" >Físico </option>}
                                {!plan.habs?.includes("extra") && <option value="extra" >Extra </option>}
                                {!plan.habs?.includes("email") && <option value="email" >Envio a su correo </option>}
                                {!plan.habs?.includes("newsletter") && <option value="newsletter">Inclusión al NewsLetter</option>}
                                <option value="other" disabled>Agregar</option>
                            </Input>
                        </div>
                        <div className="hab mt-2 hab-default flex-column">
                            <small className="text-start">Agregar permisos personalizados</small>
                            <div className="hab">
                                <Input type="text" placeholder="Nuevo permiso personalizado" innerRef={newHabRef} />
                                <Button color="blue" onClick={() => {
                                    let p: MagazzinePlan = clone(plan);

                                    if (!p.habs?.includes(newHabRef.current.value)) {
                                        p.habs!.push(newHabRef.current.value);

                                        setPlan(p);

                                        setTimeout(() => {
                                            newHabRef.current.value = "";
                                        }, 200)
                                    } else {
                                        toast.error("No es posible agregar un elemento duplicado")
                                    }

                                }}>Agregar</Button>
                            </div>
                        </div>
                    </Collapse>
                </div>

                <textarea defaultValue={plan.description} rows={5} placeholder="Descripción de este plan de revista" className="description-plan mb-2" ref={descriptionRef} onBlur={() => {
                }}></textarea>

            </div>
            <div className="d-flex flex-row gap-2 w-100">
                <Button style={{ fontSize: ".8rem", marginLeft: "auto" }} className="mt-auto w-auto" color="blue" onClick={() => { savePlan(); setIsEditted(false); }}>Guardar</Button>
                <Button style={{ fontSize: ".8rem" }} className=" mb-0 w-auto" onClick={() => { setPlan(props.plan); setIsEditted(false); if (props.isNew) { window.location.reload() } }}>Cancelar</Button>
            </div>
        </div>

    } else {

        return <div className="card-magazzine-plan sides-min-x sides-min-y" key={"magazzine-card-" + props.index} style={{ position: "relative" }}>


            <i onClick={() => {
                setIsShow(!isShow);
            }} className={`bi bi-chevron-${!isShow ? 'down' : 'up'}`} color="blue" style={{ position: "absolute", top: ".5rem", right: "2rem", width: "1rem", height: "1rem", cursor: "pointer" }}></i>
            <svg onClick={() => {
                props.onCopy(plan);
            }} className="icon-copy" color="blue" style={{ position: "absolute", top: ".5rem", right: ".5rem", width: "1rem", height: "1rem", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 448H64V224h64V160H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64zm-64-96H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64z" /></svg>

            <h4>{plan.name}</h4>

            <p >{durationPlan()}</p>

            <h2 >{plan.price} <small>Bs./u</small></h2>

            <Collapse isOpened={isShow}>
                <div className="winning-magazzine-plans-container">
                    {
                        plan.winnings.map((winning: Winning, ii: number) => {
                            return <div className="winning-magazzine-plan">
                                <div>
                                    <i className={"bi bi-" + winning.icon}></i>
                                    <span>{winning.text}</span>
                                </div>
                            </div>
                        }
                        )
                    }

                    <div className="habs-disponible">
                        <div className="w-100 d-flex flex-row align-items-center gap-4">
                            <h4 className="my-0 block " >Contenido habilitado para el suscriptor</h4>
                            <Button color="blue" className="px-2 py-0" style={{ fontSize: ".8rem" }} onClick={() => {
                                setViewHabs(!viewHabs)
                            }}>{viewHabs ? <b className="bi bi-eye-slash text-white" ></b> : <b className="bi bi-eye text-white"></b>}</Button>
                        </div>
                        <Collapse isOpened={viewHabs}>
                            {
                                plan.habs!.map((hab: string, index: number) => {
                                    return <div className="hab">
                                        <Label  >
                                            {
                                                generateHab(hab)
                                            }
                                        </Label>

                                    </div>
                                })
                            }


                        </Collapse>
                    </div>
                </div>
            </Collapse>

            <small className="text-start mt-3 pt-2 w-100" style={{ borderTop: "1px solid rgba(0,0,0, .1)" }}>Descripción</small>
            <textarea readOnly style={{ border: "none", padding: "0" }} rows={5} placeholder="Descripción de este plan de revista" className="description-plan mb-2" defaultValue={plan.description}></textarea>

            <div className="d-flex flex-row gap-2 w-100">
                <a href={"https://pagos.nuevaeconomia.com.bo/plan?id=" + plan.plan_code} target="_blank" className="mt-auto w-auto mt-auto btn btn-success" style={{ fontSize: ".8rem", color: "white", fill: "white" }} color="white" onClick={() => { }}>
                    <div className="bi bi-box-arrow-up-right" style={{ color: "white ", fill: "white " }}></div>
                </a>
                <Button className="mt-auto w-100 mt-auto" style={{ fontSize: ".8rem" }} color="blue" onClick={() => { setIsEditted(true) }}>Editar</Button>
                <Button className="mt-0 mb-0 w-100" style={{ fontSize: ".8rem" }} color={plan.active == 1 ? "danger" : "secondary"} onClick={() => { deshabilitar() }}>{plan.active == 1 ? "Deshabilitar" : "Habilitar"}</Button>
            </div>
        </div>
    }
}

export default MagazzinePlanCard;
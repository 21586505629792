import React from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./theme/globals.scss";
import "bootstrap-icons/font/bootstrap-icons.scss";

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Login from './pages/Login';
import Admin from './pages/Admin';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Manager from './pages/Manager';
import { routes } from './common/routes';
import Profile from './pages/Profile';
import Users from './pages/Users';
import Pagos from './pages/Pagos';
import Links from './pages/Links';

function App() {

  return (
    <div className="App">

      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin" element={<Manager />} />
          <Route path={routes.nuevaeco} element={<Admin />} />

          <Route path={routes.profile} element={<Profile />} />
          <Route path={routes.users} element={<Users />} />
          <Route path={routes.pagos} element={<Pagos />} />

          <Route path={routes.links} element={<Links />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Col, Input, Button, ModalHeader, Modal } from "reactstrap";
import { GenerateErrorsReport, InputEditable, LoadProgress } from "../components/comps";
import Header from "../components/Header";
import { post, updateReturnType, userKeys, userType } from "../theme/variables";

import "../theme/styles/pages/Profile.scss";
import { UpdatePass } from "../components/AdminForms";
import { updatePass } from "../functions/AdminFunctions";

const Profile = () => {
    const [user, setUser] = useState({} as userType);
    const [imgProfile, setImgProfile] = useState("");
    const [userRol, setUserRol] = useState([] as string[]);
    const [modalInfo, setModalInfo] = useState({ open: false, action: "" as ("update-pass" | "") });

    const navigate = useNavigate();

    let saveDataProfile = (value: string, elem: userKeys) => {
        let form = new FormData();
        form.append("clave", "update");
        form.append("elem", elem);
        form.append("new-value", value);
        form.append("user", user.user_code.toString());
        form.append("passmd5", user.pass);

        post("admin.php", form, {}).then(r => {
            let res = r as updateReturnType;

            let usr: any = user;
            usr[elem] = value;

            setUser(usr);

            if (res.result) {
                toast.success("Datos actualizados correctamente");
                localStorage.setItem("user-auth", JSON.stringify(usr));
            } else {
                toast.error("Error al actualizar los datos: " + res.exceptions.message);
            }

            //onFinish();
        });
    }

    useEffect(() => {
        //Update Image 
        if (imgProfile !== "") {
            //Guardar en el server
            toast.info("Actualizando tu fotografia");
            saveDataProfile(imgProfile, "imagen");
            setImgProfile("");
        }
    }, [imgProfile]);

    useEffect(() => {
        let usr: userType = JSON.parse(localStorage.getItem("user-auth")!);

        if (usr) {
            setUser(usr);
            setUserRol(usr.rol.split("|"));
        } else {
            navigate("/");
        }
    }, [])

    let closeModal = () => {
        setModalInfo({ open: false, action: "" });
    }
    //Components
    let ModalRender = () => {
        const [percentProgress, setPercentProgress] = useState(0);

        return <Modal isOpen={modalInfo.open} centered >

            <ModalHeader>

                <LoadProgress percent={percentProgress} />

                {
                    modalInfo.action === "update-pass" ?
                        "Cambiar contraseña"
                        :
                        ""


                }
            </ModalHeader>
            {
                modalInfo.action === "update-pass" ?
                    <UpdatePass onCancel={() => {
                        closeModal();
                    }} onConfirm={(ant: string, nuev: string) => {
                        updatePass(user, ant, nuev, () => {
                            //On sucesss
                            closeModal();
                        }, () => {
                            //On error
                            closeModal();
                        })
                    }} />
                    :
                    <></>

            }

        </Modal>
    }

    return <div className="profile-page">
        <Header />

        <ModalRender />

        <div className='admin-profile px-4'>

            <Col className='admin-profile-img' xs="3">
                <div>
                    <Input type='file' onChange={(ev) => {
                        if (ev.target.files![0]) {
                            //Open as Base64 img
                            let fr = new FileReader();
                            fr.addEventListener("load", (e) => {
                                setImgProfile(e.target?.result as string);
                            });
                            fr.readAsDataURL(ev.target.files![0]);
                        }
                    }} />
                    <span>Cambiar foto</span>
                </div>
                <img src={imgProfile !== "" ? imgProfile : user.imagen ? user.imagen : "https://illumesense.com/resources/illumesense/style/img/website/profile-picture-blanks/male-profile.jpg"} alt="Img profile" />
            </Col>

            {userRol.includes("admin") ?
                <Col className='admin-profile-datos' xs="9">
                    <Row>
                        <InputEditable defaultValue={user.nombre} placeholder='Nombre' textType="h2" onSave={(inp) => {
                            //Guardar nombre nuevo, solo en admin
                            saveDataProfile(inp.value, "nombre");
                        }} />
                    </Row>
                    <Row>
                        <InputEditable defaultValue={user.cargo} placeholder='Cargo' textType="h3" onSave={(inp) => {
                            //Guardar cargo nuevo, solo en admin
                            saveDataProfile(inp.value, "cargo");
                        }} />
                    </Row>
                    <Row>
                        <InputEditable defaultValue={user.area} placeholder='Area' textType="h4" onSave={(inp) => {
                            //Guardar area nueva, solo en admin
                            saveDataProfile(inp.value, "area");
                        }} />
                    </Row>
                    <Row>
                        <Col xs="6">
                            <InputEditable defaultValue={user.celular} placeholder='Celular' textType="h5" onSave={(inp) => {
                                //Guardar celular nueva, solo en admin
                                saveDataProfile(inp.value, "celular");
                            }} />
                        </Col>
                        <Col xs="6">
                            <InputEditable defaultValue={user.email} placeholder='Correo' textType="h5" onSave={(inp) => {
                                //Guardar correo nueva, solo en admin
                                saveDataProfile(inp.value, "email");
                            }} />
                        </Col>
                    </Row>
                </Col>
                :
                <Col className='admin-profile-datos' xs="9">
                    <span className='row h2'>{user.nombre}</span>
                    <span className='row h3'>{user.cargo}</span>
                    <span className='row h4'>{user.area}</span>
                    <Row>
                        <Col xs="6">
                            <InputEditable defaultValue={user.celular} placeholder='Celular' textType="h5" onSave={(inp) => {
                                //Guardar celular nueva, solo en admin
                                saveDataProfile(inp.value, "celular");
                            }} />
                        </Col>
                        <Col xs="6">
                            <InputEditable defaultValue={user.email} placeholder='Correo' textType="h5" onSave={(inp) => {
                                //Guardar correo nueva, solo en admin
                                saveDataProfile(inp.value, "email");
                            }} />
                        </Col>
                    </Row>
                </Col>
            }

            <hr className="w-100" />

            <div className="pass-manager">
                <Row className='d-flex mt-5 mb-4 justify-content-center d-flex flex-row justify-content-center align-items-center'>
                    <Button className='mb-3' color='secondary' onClick={() => {
                        //Cambiar contra
                        setModalInfo({ open: true, action: "update-pass" })

                    }}>Cambiar contraseña</Button>
                    <Button className='mb-3' color="primary" onClick={() => {
                        localStorage.clear();
                        navigate("/");
                    }}>Cerrar sesión</Button>

                </Row>
                <Row className='mb-2'>
                    <a href='mailto:ccopa@rednuevaempresa.com'>Contactar con soporte</a>
                </Row>

                <GenerateErrorsReport className="mb-5" />
            </div>

        </div>

    </div>
}

export default Profile;

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";
import { loginReturnType, post, usersType } from "../theme/variables";

import "./scss/Login.scss";

let Login = () => {

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        let usr = localStorage.getItem("user-auth");
        if(usr){
            setRedirect(true);
        }
    }, [])

    let submitForm = (ev: any) => {
        ev.target.disabled = true;
        toast.info("Iniciando sesion");

        let us = document.getElementById("usuario-form-login") as HTMLInputElement;
        let pss = document.getElementById("pss-form-login") as HTMLInputElement;
        let rm = document.getElementById("check-remember-form-login") as HTMLInputElement;



        let formdata = new FormData();
        formdata.append("user", us.value);
        formdata.append("pass", pss.value);
        formdata.append("clave", "login")

        post("admin.php", formdata, {}).then(res => {
            let r = res as loginReturnType;
            
            if (r.confirmed) {
                //Notificacion
                toast.success("Iniciaste sesion correctamente.");
                //Si se confirma "Recordar credenciales" se lo almacena en memoria 
                localStorage.setItem("user-auth", JSON.stringify(r.user));
                //Almacenaje de la info de otros usuarios en caso de ser admin
                if(r.user.rol === "admin"){
                    localStorage.setItem("workers", JSON.stringify(r.others));
                }
                                
                setRedirect(true);
            } else {
                ev.target.disabled = false;
                toast.error("Credenciales incorrectos, intenta nuevamente");
                us.value = "";
                pss.value = "";
            }
        })
    }

    return <Container className="login-page container-md d-flex align-items-center justify-content-center w-100 h-100">
        {redirect ? <Navigate to="/admin" /> : <></>}
        <Form inline className="form-style login-area p-4 container-dark-blue text-white d-flex align-items-center flex-column" method="post">
            <h4 className="text-center w-100 mb-4">Iniciar sesión</h4>
            <FormGroup className="py-1 w-100">

                <Input
                    id="usuario-form-login"
                    name="email"
                    placeholder="Usuario"
                    type="email"
                />
            </FormGroup>
            <FormGroup className="py-1 w-100">

                <Input
                    id="pss-form-login"
                    name="password"
                    placeholder="Contraseña"
                    type="password"
                />
            </FormGroup>
            {/*
                <FormGroup className="py-1 w-100">
                    <Input
                        id="check-remember-form-login"
                        name="rememberme"
                        type="checkbox"
                    />
                    <Label for="check-remember-form-login" className="px-1">Recordar mis credenciales</Label >
                </FormGroup>
            */}
            <FormGroup className="d-flex justify-content-center w-100">
                <Button className="button-primary" onClick={submitForm}>
                    INICIAR SESIÓN
                </Button>
            </FormGroup>

            <a href="mailto:ccopa@rednuevaempresa.com" className="mt-5 soporte-link">Contactar con soporte</a>
        </Form>

    </Container>
}

export default Login;
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { addEdiciones, addNota, addRankings, confirmEdicion, confirmNota, confirmRanking, deleteEdicion, deleteNota, deleteRanking, errorOnRequest } from "../functions/AdminFunctions";
import { DeleteIcon, DoneIcon, EditIcon, notaDiaType, notasDiaType, post, RefreshIcon, userType } from "../theme/variables";
import { Confirm, Delete, EdicionesForm, NotasForm, RankingsForm } from "./AdminForms";
import { LoadProgress, Title } from "./comps";

import "./scss/Calidad.scss";

let CardCalidad = (props: { elem: any, onEdit: (val: any) => any, onConfirm: (val: any) => any, onDelete: (val: any) => any }) => {

    let confirmNote = () => {
        props.onConfirm(props.elem);
    }

    let deleteNote = () => {
        props.onDelete(props.elem);
    }

    let editNote = () => {
        props.onEdit(props.elem);
    }

    return <Row className="bg-white w-100 d-flex align-items-center card-calidad-component">
        <Col xs="1" onClick={editNote}>
            {EditIcon}
        </Col>
        <Col xs="1" onClick={confirmNote}>
            {DoneIcon}
        </Col>
        <Col xs="1" onClick={deleteNote}>
            {DeleteIcon}
        </Col>

        <Col xs="8">
            <Row className="mb-0">
                <Label className="titulo-card-calidad">{props.elem.titulo}</Label>
            </Row>
            <Row>
                <Label className="fecha-card-calidad">{props.elem.fecha.replaceAll("-", "/")}</Label>
            </Row>
        </Col>
    </Row>
}


let Calidad = (props: { user: userType }) => {
    //Obtener notas sin confirmar
    const [elementos, setElementos] = useState({ notas: [] as any, ediciones: [] as any, rankings: [] as any });
    const [cargado, setCargado] = useState([false, false, false]);

    const [modalInfo, setModalInfo] = useState({ open: false, action: "" as "" | "edit-nota" | "edit-edicion" | "edit-ranking" | "confirm-nota" | "confirm-edicion" | "confirm-ranking" | "delete-nota" | "delete-edicion" | "delete-ranking" });

    const [activeTabCalidad, setActiveTabCalidad] = useState(1);

    const defaultAction = {} as any;

    const [actionConfirm, setActionConfirm] = useState(defaultAction);
    const [actionDelete, setActionDelete] = useState(defaultAction)

    const [notaSelected, setNotaSelected] = useState({} as any);
    const [edicionSelected, setEdicionSelected] = useState({} as any);
    const [rankingSelected, setRankingsSelected] = useState({} as any);

    const [errorNotas, setErrorNotas] = useState(false);
    const [errorEdiciones, setErrorEdiciones] = useState(false);
    const [errorRankings, setErrorRankings] = useState(false);

    let obtainNotas = () => {
        setCargado([false, cargado[1], cargado[2]]);
        let form = new FormData();
        form.append("clave", "obtain-notes-no-confirm");
        form.append("user", props.user.email);
        form.append("pass", props.user.pass);

        setErrorNotas(false);

        post("admin.php", form, {}).then(r => {
            let res = r as { result: boolean, exceptions: string, values: any };

            if (res.result) {
                setElementos({ notas: res.values, ediciones: elementos.ediciones, rankings: elementos.rankings });
                setCargado([true, cargado[1], cargado[2]]);
            } else {
                toast.error("Ha ocurrido un error obteniendo las notas: " + res.exceptions);
            }
        }).catch(err => {
            console.log(err)
            errorOnRequest(err);
            setErrorNotas(true);
        })
    }
    let obtainEdiciones = () => {
        setCargado([cargado[0], false, cargado[2]]);
        let form = new FormData();
        form.append("clave", "obtain-ediciones-no-confirm");
        form.append("user", props.user.email);
        form.append("pass", props.user.pass);

        setErrorEdiciones(false);

        post("admin.php", form, {}).then(r => {
            let res = r as { result: boolean, exceptions: string, values: any };

            if (res.result) {
                setElementos({ notas: elementos.notas, ediciones: res.values, rankings: elementos.rankings });
                setCargado([cargado[0], true, cargado[2]]);
            } else {
                toast.error("Ha ocurrido un error obteniendo las ediciones: " + res.exceptions);
            }
        }).catch(err => {
            console.log(err)
            errorOnRequest(err)
            setErrorEdiciones(true);
        })
    }

    let obtainRankings = () => {
        setCargado([cargado[0], cargado[1], false]);
        let form = new FormData();
        form.append("clave", "obtain-rankings-no-confirm");
        form.append("user", props.user.email);
        form.append("pass", props.user.pass);

        setErrorRankings(false);

        post("admin.php", form, {}).then(r => {
            let res = r as { result: boolean, exceptions: string, values: any };

            if (res.result) {
                setElementos({ notas: elementos.notas, ediciones: elementos.ediciones, rankings: res.values });
                setCargado([cargado[0], cargado[1], true]);
            } else {
                toast.error("Ha ocurrido un error obteniendo los rankings: " + res.exceptions);
            }

        }).catch(err => {
            console.log(err)
            errorOnRequest(err)
            setErrorRankings(true);
        })
    }

    let obtainNoConfirm = () => {
        setCargado([false, false, false]);

        let form = new FormData();
        form.append("clave", "obtain-no-confirm");
        form.append("user", props.user.email);
        form.append("pass", props.user.pass);

        setErrorEdiciones(false);
        setErrorNotas(false);
        setErrorRankings(false);

        post("admin.php", form, {}).then(r => {
            let res = r as { result: boolean, exceptions: string, values: { notas: any, ediciones: any, rankings: any } };

            if (res.result) {
                setElementos(res.values);
                setCargado([true, true, true]);
            } else {
                toast.error("Ha ocurrido un error obteniendo los elementos no confirmados, actualizalos manualmente: " + res.exceptions);
            }

        }).catch(err => {
            console.log(err)
            errorOnRequest(err);

            setErrorEdiciones(true);
            setErrorNotas(true);
            setErrorRankings(true);
        })
    }

    let closeModal = () => {
        setModalInfo({ open: false, action: "" });
    }

    useEffect(() => {
        obtainNoConfirm();
    }, [])

    let ModalRender = () => {
        const [percentProgress, setPercentProgress] = useState(0);
        return <Modal isOpen={modalInfo.open} centered >
            <ModalHeader>

                <LoadProgress percent={percentProgress} />

                {modalInfo.action === "edit-nota" ?
                    "Edicion de notas"
                    :
                    modalInfo.action === "edit-edicion" ?
                        "Editar ediciones"
                        :
                        modalInfo.action === "edit-ranking" ?
                            "Editar rankings"
                            :
                            modalInfo.action === "confirm-nota" ?
                                "Confirmar nota"
                                :
                                modalInfo.action === "confirm-edicion" ?
                                    "Confirmar edicion"
                                    :
                                    modalInfo.action === "confirm-ranking" ?
                                        "Confirmar ranking"
                                        :
                                        modalInfo.action === "delete-nota" ?
                                            "Eliminar nota"
                                            :
                                            modalInfo.action === "delete-edicion" ?
                                                "Eliminar edicion"
                                                :
                                                modalInfo.action === "delete-ranking" ?
                                                    "Eliminar ranking"
                                                    :
                                                    ""
                }
            </ModalHeader>
            {modalInfo.action === "edit-nota" ?
                <NotasForm
                    values={notaSelected}
                    onSave={(action: "nota-destacada" | "nota-dia", values: FormData) => {
                        addNota(props.user, action, values, (url: any) => {
                            //On Success
                            closeModal();

                            //setTextToast(url);
                        }, () => {
                            //On Error
                            closeModal();
                        }, (percent: number) => {
                            setPercentProgress(percent);
                        });
                    }}
                    onCancel={() => {
                        closeModal();
                    }} />
                :
                modalInfo.action === "confirm-nota" ?
                    <Confirm onCancel={() => {
                        setActionConfirm(defaultAction);
                        closeModal();
                    }} onConfirm={() => {
                        confirmNota(props.user, notaSelected, () => {
                            //On sucesss
                            setElementos(actionConfirm)
                            closeModal();
                        }, () => {
                            //On error
                            closeModal();
                        })
                    }} />
                    :
                    modalInfo.action === "delete-nota" ?
                        <Delete onCancel={() => {
                            setActionDelete(defaultAction);
                            closeModal();
                        }} onDelete={() => {
                            setElementos(actionDelete)
                            deleteNota(props.user, notaSelected, () => {
                                //On sucesss
                                closeModal();
                            }, () => {
                                //On error
                                closeModal();
                            })
                        }} />
                        :
                        modalInfo.action === "edit-edicion" ?
                            <EdicionesForm
                                values={edicionSelected}
                                onSave={(action: "ediciones" | "informes" | "separatas", values: FormData) => {
                                    addEdiciones(props.user, action, values, (url: any) => {
                                        //On Success
                                        closeModal();

                                        //setTextToast(url);
                                    }, () => {
                                        //On Error
                                        closeModal();
                                    }, (percent: number) => {
                                        setPercentProgress(percent);
                                    });
                                }}
                                onCancel={() => {
                                    closeModal();
                                }} />
                            :
                            modalInfo.action === "confirm-edicion" ?
                                <Confirm onCancel={() => {
                                    setActionConfirm(defaultAction);
                                    closeModal();
                                }} onConfirm={() => {
                                    confirmEdicion(props.user, edicionSelected, () => {
                                        //On sucesss
                                        setElementos(actionConfirm)
                                        closeModal();
                                    }, () => {
                                        //On error
                                        closeModal();
                                    })
                                }} />
                                :
                                modalInfo.action === "delete-edicion" ?
                                    <Delete onCancel={() => {
                                        setActionDelete(defaultAction);
                                        closeModal();
                                    }} onDelete={() => {
                                        setElementos(actionDelete)
                                        deleteEdicion(props.user, edicionSelected, () => {
                                            //On sucesss
                                            closeModal();
                                        }, () => {
                                            //On error
                                            closeModal();
                                        })
                                    }} />
                                    :
                                    modalInfo.action === "edit-ranking" ?
                                        <RankingsForm
                                            values={rankingSelected}
                                            onSave={(action: "empresas" | "financiero", values: FormData) => {
                                                addRankings(props.user, action, values, (url: any) => {
                                                    //On Success
                                                    closeModal();

                                                    //setTextToast(url);
                                                }, () => {
                                                    //On Error
                                                    closeModal();
                                                }, (percent: number) => {
                                                    setPercentProgress(percent);
                                                });
                                            }}
                                            onCancel={() => {
                                                closeModal();
                                            }} />
                                        :
                                        modalInfo.action === "confirm-ranking" ?
                                            <Confirm onCancel={() => {
                                                setActionConfirm(defaultAction);
                                                closeModal();
                                            }} onConfirm={() => {
                                                confirmRanking(props.user, rankingSelected, () => {
                                                    //On sucesss
                                                    setElementos(actionConfirm)
                                                    closeModal();
                                                }, () => {
                                                    //On error
                                                    closeModal();
                                                })
                                            }} />
                                            :
                                            modalInfo.action === "delete-ranking" ?
                                                <Delete onCancel={() => {
                                                    setActionDelete(defaultAction);
                                                    closeModal();
                                                }} onDelete={() => {
                                                    setElementos(actionDelete)
                                                    deleteRanking(props.user, rankingSelected, () => {
                                                        //On sucesss
                                                        closeModal();
                                                    }, () => {
                                                        //On error
                                                        closeModal();
                                                    })
                                                }} />
                                                :
                                                <></>}

        </Modal>;
    }

    return <>

        <ModalRender />

        <div className="calidad-component px-2 my-3">

            <div className='calidad-seccion'>

                <Title text='Control de calidad'></Title>

                <Nav tabs className="row mx-0 w-100">
                    
                    <Col xs="10" sm="9" className="px-0">
                        <Row className="mx-0">
                            <NavItem className="col-auto px-0" onClick={() => {
                                setActiveTabCalidad(1);
                            }}>
                                <NavLink className={activeTabCalidad === 1 ? 'active' : ''} >
                                    Notas
                                </NavLink>
                            </NavItem>
                            <NavItem className="col-auto px-0" onClick={() => {
                                setActiveTabCalidad(2);
                            }}>
                                <NavLink className={activeTabCalidad === 2 ? 'active' : ''} >
                                    Ediciones
                                </NavLink>
                            </NavItem>
                            <NavItem className="col-auto px-0" onClick={() => {
                                setActiveTabCalidad(3);
                            }}>
                                <NavLink className={activeTabCalidad === 3 ? 'active' : ''} >
                                    Rankings
                                </NavLink>
                            </NavItem>
                        </Row>
                    </Col>
                    <Col xs="2" sm="3" className="d-flex justify-content-end btn-update">
                        <Button className="" onClick={() => {
                            switch (activeTabCalidad) {
                                case 1: obtainNotas(); break;
                                case 2: obtainEdiciones(); break;
                                case 3: obtainRankings(); break;
                                default: obtainNoConfirm(); break;
                            }
                        }}>
                            {RefreshIcon}
                            {activeTabCalidad === 1 ?
                                <span>Actualizar notas</span>
                                :
                                activeTabCalidad === 2 ?
                                    <span>Actualizar ediciones</span>
                                    :
                                    activeTabCalidad === 3 ?
                                        <span>Actualizar rankings</span>
                                        :
                                        <></>
                            }
                        </Button>

                    </Col>
                </Nav>
                <TabContent activeTab={activeTabCalidad}>
                    <TabPane tabId={1}>

                        {cargado[0] ?
                            elementos.notas.map((elem: any, index: number) => {
                                return <CardCalidad elem={elem} onEdit={(nt: any) => {
                                    setNotaSelected(nt);

                                    setModalInfo({ open: true, action: "edit-nota" });
                                }} onConfirm={(nr: any) => {
                                    setNotaSelected(nr);

                                    let not: notasDiaType = JSON.parse(JSON.stringify(elementos.notas));
                                    let ress = not.filter(el => el.codigo_nota !== nr.codigo_nota);
                                    setActionConfirm({ notas: ress, ediciones: elementos.ediciones, rankings: elementos.rankings });

                                    setModalInfo({ open: true, action: "confirm-nota" });
                                }} onDelete={(nt: any) => {
                                    setNotaSelected(nt);

                                    let not: notasDiaType = JSON.parse(JSON.stringify(elementos.notas));
                                    let ress = not.filter(el => el.codigo_nota !== nt.codigo_nota);
                                    setActionDelete({ notas: ress, ediciones: elementos.ediciones, rankings: elementos.rankings });

                                    setModalInfo({ open: true, action: "delete-nota" });
                                }} />
                            })
                            :
                            <Label className="w-100 text-center">
                                {errorNotas ? "Ha ocurrido un error, intenta nuevamente" : "Cargando notas para control de calidad... Podría tomar varios minutos."}
                            </Label>
                        }

                    </TabPane>

                    {/*Ediciones*/}

                    <TabPane tabId={2} >
                        {cargado[1] ?
                            elementos.ediciones.map((elem: any, index: number) => {
                                return <CardCalidad elem={elem} onEdit={(nt: any) => {
                                    setEdicionSelected(nt);

                                    setModalInfo({ open: true, action: "edit-edicion" });
                                }} onConfirm={(nr: any) => {
                                    setEdicionSelected(nr);

                                    let not: any = JSON.parse(JSON.stringify(elementos.ediciones));
                                    let ress = not.filter((el: { codigo_elemento: any; }) => el.codigo_elemento !== nr.codigo_elemento);
                                    setActionConfirm({ notas: elementos.notas, ediciones: ress, rankings: elementos.rankings });

                                    setModalInfo({ open: true, action: "confirm-edicion" });
                                }} onDelete={(nt: any) => {
                                    setEdicionSelected(nt);

                                    let not: any = JSON.parse(JSON.stringify(elementos.ediciones));
                                    let ress = not.filter((el: { codigo_elemento: any; }) => el.codigo_elemento !== nt.codigo_elemento);
                                    setActionDelete({ notas: elementos.notas, ediciones: ress, rankings: elementos.rankings });

                                    setModalInfo({ open: true, action: "delete-edicion" });

                                }} />
                            })
                            :
                            <Label className="w-100 text-center">
                                {errorEdiciones ? "Ha ocurrido un error, intenta nuevamente" : "Cargando ediciones para control de calidad... Podría tomar varios minutos."}
                            </Label>
                        }
                    </TabPane>

                    {/** Rankings */}

                    <TabPane tabId={3}>
                        {cargado[2] ?
                            elementos.rankings.map((elem: any, index: number) => {
                                return <CardCalidad elem={elem} onEdit={(nt: any) => {
                                    setRankingsSelected(nt);

                                    setModalInfo({ open: true, action: "edit-ranking" });
                                }} onConfirm={(nr: any) => {
                                    setRankingsSelected(nr);

                                    let not = JSON.parse(JSON.stringify(elementos.rankings));
                                    let ress = not.filter((el: { codigo_elemento: any; }) => el.codigo_elemento !== nr.codigo_elemento);
                                    setActionConfirm({ notas: elementos.notas, ediciones: elementos.ediciones, rankings: ress });

                                    setModalInfo({ open: true, action: "confirm-ranking" });
                                }} onDelete={(nt: any) => {
                                    setRankingsSelected(nt);

                                    let not = JSON.parse(JSON.stringify(elementos.rankings));
                                    let ress = not.filter((el: { codigo_elemento: any; }) => el.codigo_elemento !== nt.codigo_elemento);
                                    setActionDelete({ notas: elementos.notas, ediciones: elementos.ediciones, rankings: ress });

                                    setModalInfo({ open: true, action: "delete-ranking" });

                                }} />
                            })
                            :
                            <Label className="w-100 text-center">
                                {errorRankings ? "Ha ocurrido un error, intenta nuevamente" : "Cargando rankings para control de calidad... Podría tomar varios minutos."}
                            </Label>
                        }
                    </TabPane>
                </TabContent>
            </div>


        </div>

    </>
}
export default Calidad;

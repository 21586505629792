import { useEffect, useState } from "react";
import Header from "../components/Header";
import NativeTitle from "../components/Title";
import "./scss/Links.scss";
import { ResponseGeneral } from "../common/types";
import { post, tk } from "../theme/variables";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { formatDate } from "../common/variables";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export type Link = {
    id: string,
    position: string,
    url: string,
    content: string,
    clicks: number,
    type: "gne" | "melf",
    visible: 1 | 0,
    active: 1 | 0
}

const Links = () => {

    const [lks, setLks] = useState<{ isLoad: boolean, links: Link[] }>({ isLoad: false, links: [] });
    const [modal, setModal] = useState({ isOpen: false, action: "", vals: [] as any[] });

    const [filters, setFilters] = useState({ start: "2023-01-02T00:00", end: formatDate(new Date()), type: "gne" as "gne" | "melf" });

    useEffect(() => {

    }, [])

    useEffect(() => {
        getLinks();
    }, [filters])

    useEffect(() => {
        console.log(filters)
    }, [filters.type])

    const getLinks = () => {
        const form = new FormData();
        form.append('key', 'get-all-clicks-filters')

        form.append('start', filters.start);
        form.append('end', filters.end);
        post(
            "adminLinks.php",
            form,
            {}
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setLks({ isLoad: true, links: res.VALUE });

                order(res.VALUE);
            }
        })
    }

    const handleDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        console.log(result)

        const items = Array.from(lks.links);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        //setTableData(items);
        //console.log(items)

        order(items);

        setLks({ isLoad: true, links: items })
    };

    const order = (items: Link[]) => {
        let pairIdPosition: { id: string, position: string }[] = [];
        items.forEach((l: Link, index: number) => {
            pairIdPosition.push({ id: l.id, position: (items.length - index).toString() });
        })

        console.log(pairIdPosition)

        const form = new FormData();

        form.append('token', tk);
        form.append('key', 'reorder');
        form.append('newValues', JSON.stringify(pairIdPosition));

        post(
            "adminLinks.php",
            form, {}
        )
    }

    const openEdit = (l: Link) => {
        setModal({ isOpen: true, action: "edit", vals: [l.content, l.url, l.position, l.id, l.type] })
    }

    const create = () => {
        toast.info("Se está creando el enlace");
        const form = new FormData();

        form.append("key", "create");
        form.append("content", (document.getElementById("content-form") as HTMLInputElement).value)
        form.append("url", (document.getElementById("url-form") as HTMLInputElement).value)
        form.append("position", (lks.isLoad ? (lks.links.length + 1).toString() : '1'))
        form.append("type", (document.getElementById("type-form") as HTMLSelectElement).value)
        form.append("token", tk)

        post(
            "adminLinks.php",
            form,
            {}
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("Se ha creado el enlace");

                setModal({ isOpen: false, action: "", vals: [] });
                setLks({ isLoad: lks.isLoad, links: [...lks.links, res.VALUE] })
            } else {
                toast.error("Un error ha ocurrido, intenta nuevamente más tarde ")
            }
        }).catch(err => {
            toast.error("Un error ha ocurrido, intenta nuevamente más tarde")
        })
    }

    const edit = () => {
        toast.info("Se está editando el enlace")
        const form = new FormData();

        form.append("key", "edit");
        form.append("content", (document.getElementById("content-form") as HTMLInputElement).value)
        form.append("url", (document.getElementById("url-form") as HTMLInputElement).value)
        form.append("position", modal.vals[2])
        form.append("id", modal.vals[3])
        form.append("type", (document.getElementById("type-form") as HTMLSelectElement).value)
        form.append("token", tk)

        post(
            "adminLinks.php",
            form,
            {}
        ).then(r => {
            getLinks();

            setModal({ isOpen: false, action: "", vals: [] });
        }).catch(err => {
            toast.error("Un error ha ocurrido, intenta nuevamente más tarde")
        })
    }

    const visibility = (l: Link) => {
        // eslint-disable-next-line no-restricted-globals
        let doit = confirm(`Desea ${l.visible == 1 ? 'ocultar' : 'hacer visible'} el enlace`);

        if (doit) {
            const form = new FormData();
            form.append('key', l.visible ? 'hidde' : 'unhidde');
            form.append('token', tk);
            form.append('id', l.id);

            post(
                "adminLinks.php",
                form, {}
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    toast.success("Realizado");

                    getLinks();
                } else {
                    toast.error("Un error ha ocurrido, intenta nuevamente más tarde")
                }
            }).catch(err => {
                toast.error("Un error ha ocurrido, intenta nuevamente más tarde")
            })
        }
    }

    const del = (l: Link) => {
        // eslint-disable-next-line no-restricted-globals
        let doit = confirm(`Desea eliminar el enlace`);

        if (doit) {
            const form = new FormData();
            form.append('key', 'delete');
            form.append('token', tk);
            form.append('id', l.id);

            post(
                "adminLinks.php",
                form, {}
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    toast.success("Realizado");

                    getLinks();
                } else {
                    toast.error("Un error ha ocurrido, intenta nuevamente más tarde")
                }
            }).catch(err => {
                toast.error("Un error ha ocurrido, intenta nuevamente más tarde")
            })
        }
    }

    const update = (ev: any) => {
        if (ev.target.name == "start") {
            setFilters({ start: ev.target.value, end: filters.end, type: filters.type })
        } else {
            setFilters({ start: filters.start, end: ev.target.value, type: filters.type })
        }
    }

    return <div className="pagos-page">
        <Header />
        <div className="pagos-content admin-container d-flex flex-column justify-content-center align-items-center">
            <div className="w-100 d-flex justify-content-between align-items-center gap-2" style={{ marginBottom: "2rem", flexFlow: "wrap row" }}>
                <NativeTitle className="mb-0">
                    Enlaces
                </NativeTitle>
                <div className="gap-2" style={{ display: "flex", flexFlow: "nowrap row", alignItems: "stretch", justifyContent: "center" }}>
                    <Input type="select" className="btn btn-blue button" style={{ color: "white" }} onChange={(ev: any) => {
                        setFilters({ start: filters.start, end: filters.end, type: ev.target.value })
                    }}>
                        <option className="bg-white text-black" value="gne">GNE</option>
                        <option className="bg-white text-black" value="melf">Modelando el Futuro</option>
                    </Input>
                    <button className="btn btn-blue" style={{ display: "flex", flexFlow: "nowrap row" }} onClick={() => {
                        setModal({ isOpen: true, action: "create", vals: [] })
                    }}>
                        <i className="bi bi-plus"></i>
                        Agregar
                    </button>
                </div>

                <div className="c d-flex w-100 align-items-center justify-content-end gap-2 " style={{ marginLeft: "auto", flexFlow: "wrap row" }}>
                    <small>Ver desde</small>

                    <input name="start" type="datetime-local" onChange={update} defaultValue={(new Date(2023, 0, 1)).toISOString().slice(0, 16)} />

                    <small> hasta </small>
                    <input name="end" type="datetime-local" onChange={update} defaultValue={formatDate(new Date())} />


                </div>
            </div>

            <div className="w-100 table-responsive">
                {
                    lks.isLoad ?
                        lks.links.length > 0 ?
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="table">
                                    {(provided) => {
                                        return <table className="table bg-white" ref={provided.innerRef} {...provided.droppableProps}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ borderRight: "1px solid rgba(0,0,0,0.3)" }}></th>
                                                    <th scope="col" className="text-center" style={{ width: "10ch", borderRight: "1px solid rgba(0,0,0,0.3)" }}>Posición</th>
                                                    <th scope="col">Contenido</th>
                                                    <th scope="col">URL</th>
                                                    <th scope="col">Clicks</th>

                                                    <th scope="col" className="text-center" style={{ borderLeft: "1px solid rgba(0,0,0,0.3)", width: "4ch" }}></th>
                                                    <th scope="col" className="text-center" style={{ width: "4ch" }}></th>
                                                    <th scope="col" className="text-center" style={{ width: "4ch" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderColor: "rgba(0,0,0,0.3)" }}>
                                                {
                                                    lks.links.filter((l: Link) => { return l.type == filters.type }).map((l: Link, index: number) => {
                                                        return <tr key={"tr-" + index}>
                                                            <Draggable key={l.position} draggableId={l.position} index={index}>
                                                                {
                                                                    (provided) => {
                                                                        return <td
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className="text-center br-custom"
                                                                        >
                                                                            <i className="bi bi-grip-horizontal"></i>
                                                                        </td>
                                                                    }
                                                                }
                                                            </Draggable>
                                                            <td scope="row" className="text-center" style={{ width: "10ch", borderRight: "1px solid rgba(0,0,0,0.3)" }}>{/* l.position */} {lks.links.length - index}</td>
                                                            <td className="text-truncate" dangerouslySetInnerHTML={{ __html: l.content }}></td>

                                                            <td><a target="_blank" href={l.url} className="btn btn-blue-hover">
                                                                <i className="bi bi-box-arrow-up-right"></i></a></td>
                                                            <td>{l.clicks}</td>

                                                            <td className="text-center pr-0" style={{ borderLeft: "1px solid rgba(0,0,0,0.3)", width: "4ch" }}>
                                                                <button className="btn btn-blue-hover w-100 h-100" onClick={() => { visibility(l) }}>
                                                                    <i className={`bi bi-eye${!l.visible ? '-slash' : ''}`}></i>
                                                                </button>
                                                            </td>
                                                            <td className="text-center px-0" style={{ width: "4ch" }}>
                                                                <button className="btn btn-blue-hover w-100 h-100" onClick={() => { del(l) }}>
                                                                    <i className="bi bi-trash"></i>
                                                                </button>
                                                            </td>
                                                            <td className="text-center pl-0" style={{ width: "4ch" }}>
                                                                <button className="btn btn-blue-hover w-100 h-100" onClick={() => { openEdit(l) }}>
                                                                    <i className="bi bi-pencil"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })

                                                }
                                            </tbody>
                                        </table>
                                    }}
                                </Droppable>
                            </DragDropContext>
                            :
                            <span className="d-block mt-6">Sin elementos disponibles</span>
                        :
                        <span className="d-block mt-6">Cargando ...</span>

                }
            </div>
        </div>

        <Modal isOpen={modal.isOpen} centered>
            <ModalHeader className="d-flex justify-content-between w-full header-h5-inside">
                {
                    modal.action == "create" ?
                        "Crear enlace"
                        :
                        modal.action == "edit" ?
                            "Editar"
                            :
                            ""
                }

                <i className="bi bi-x" onClick={() => {
                    setModal({ isOpen: false, action: "", vals: [] })
                }}></i>
            </ModalHeader>

            <ModalBody>
                {
                    modal.action == "create" ?
                        <div className="ediciones-form-component">
                            <Input id="content-form" className="mb-3" type="textarea" defaultValue={""} placeholder="Contenido"></Input>
                            <Input id="url-form" className="my-3" type="text" defaultValue={""} placeholder="URL"></Input>
                            <Input type="select" className="mt-3" defaultValue={"gne"} id="type-form">
                                <option value="gne">GNE</option>
                                <option value="melf">Modelando el Futuro</option>
                            </Input>

                            <div className="d-flex justify-content-end gap-2" style={{}}>
                                <button className="btn btn-secondary" onClick={() => {
                                    setModal({ isOpen: false, action: "", vals: [] });
                                }}>Cancelar</button>
                                <button className="btn btn-blue" onClick={create}>Guardar</button>
                            </div>
                        </div>
                        :
                        modal.action == "edit" ?
                            <div className="ediciones-form-component">
                                <Input id="content-form" className="mb-3" type="textarea" defaultValue={modal.vals[0]} placeholder="Contenido"></Input>
                                <Input id="url-form" className="my-3" type="text" defaultValue={modal.vals[1]} placeholder="URL"></Input>
                                <Input type="select" className="mt-3" defaultValue={modal.vals[4]} id="type-form">
                                    <option value="gne">GNE</option>
                                    <option value="melf">Modelando el Futuro</option>
                                </Input>

                                <div className="d-flex justify-content-end gap-2" style={{}}>
                                    <button className="btn btn-secondary" onClick={() => {
                                        setModal({ isOpen: false, action: "", vals: [] });
                                    }}>Cancelar</button>
                                    <button className="btn btn-blue" onClick={edit}>Guardar</button>
                                </div>
                            </div>
                            :
                            <></>
                }
            </ModalBody>
        </Modal>
    </div>
}

export default Links;
import { Link } from "react-router-dom";
import { routes } from "../common/routes";
import Header from "../components/Header";
import NativeTitle from "../components/Title";
import "../theme/styles/pages/Manager.scss";

const Manager = () => {
    return <div className="manager-page">
        <Header />
        <div className="manager-content">
            <NativeTitle >Panel principal</NativeTitle>
            <div className="manager-cards">
                <Link to={routes.nuevaeco} className="manager-card">
                    <h3>Nueva Economía - <a href="https://nuevaeconomia.com.bo"><small>https://nuevaeconomia.com.bo</small></a></h3>
                    <p>
                        Gestionar:
                    </p>
                    <ul>
                        <li>- Carrusel</li>
                        <li>- Ediciones, informes especiales, y separatas</li>
                        <li>- Rankings</li>
                        <li>- Notas</li>
                    </ul>
                </Link>
                <Link to={routes.pagos} className="manager-card">
                    <h3>Pagos Nueva Economía - <a href="https://pagos.nuevaeconomia.com.bo"><small>https://pagos.nuevaeconomia.com.bo</small></a></h3>
                    <p>
                        Gestionar:
                    </p>
                    <ul>
                        <li>- Planes de revista</li>
                        <li>- Cursos virtuales, presenciales y talleres</li>
                        <li>- Compras realizadas</li>
                    </ul>
                </Link>
                <Link to={routes.users} className="manager-card">
                    <h3>Usuarios </h3>
                    <p>
                        Gestionar:
                    </p>
                    <ul>
                        <li>- Usuarios</li>
                        <li>- Roles</li>
                    </ul>
                </Link>
                <Link to={routes.links} className="manager-card">
                    <h3>Enlaces </h3>
                    <p>
                        Gestionar:
                    </p>
                    <ul>
                        <li>- Redes sociales</li>
                        <li>- Actividades</li>
                    </ul>
                </Link>
                <Link to={routes.profile} className="manager-card">
                    <h3>Tu perfil </h3>
                    <p>
                        Gestionar tus datos personales
                    </p>
                </Link>
            </div>
        </div>
    </div>
}

export default Manager;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Table, Modal, ModalHeader } from "reactstrap";
import { AddWorker, EditWorker, DeleteWorker } from "../components/AdminForms";
import { LoadProgress, Title } from "../components/comps";
import Header from "../components/Header"
import { addWorker, deleteWorker, editWorker, refreshWorkers } from "../functions/AdminFunctions";
import { usersType, RefreshIcon, EditIcon, DeleteIcon, userType } from "../theme/variables";

import "../theme/styles/pages/Users.scss";
import NativeTitle from "../components/Title";

const Users = () => {
    const [user, setUser] = useState({} as userType);
    const [modalInfo, setModalInfo] = useState({ open: false, action: "" as ("ediciones" | "rankings" | "notas" | "add-worker" | "edit-worker" | "delete-worker" | "edit-nota" | "delete-nota" | "confirm-nota" | "update-pass" | "") });
    const [workers, setWorkers] = useState([] as usersType);
    const [workerSelected, setWorkerSelected] = useState({} as userType);
    const [userRol, setUserRol] = useState([] as string[]);

    const navigate = useNavigate();

    useEffect(() => {
        let usr: userType = JSON.parse(localStorage.getItem("user-auth")!);

        if (usr) {
            setUser(usr);
            setUserRol(usr.rol.split("|"));
        } else {
            navigate("/");
        }

        let wrks = localStorage.getItem("workers");
        if (wrks) {
            let workerss: usersType = JSON.parse(wrks);
            setWorkers(workerss);
        }

    }, []);

    useEffect(() => {
        //Update workers
        localStorage.setItem("workers", JSON.stringify(workers));
    }, [workers]);

    let closeModal = () => {
        setModalInfo({ open: false, action: "" });
    }

    let openModal = (action: "ediciones" | "rankings" | "notas" | "add-worker" | "edit-worker" | "delete-worker" | "") => {
        setModalInfo({ open: true, action: action });
    }

    //Components
    let ModalRender = () => {
        const [percentProgress, setPercentProgress] = useState(0);

        return <Modal isOpen={modalInfo.open} centered >

            <ModalHeader>

                <LoadProgress percent={percentProgress} />

                {

                    modalInfo.action === "add-worker" ?
                        "Agregar"
                        :
                        modalInfo.action === "edit-worker" ?
                            "Editar"
                            :
                            modalInfo.action === "delete-worker" ?
                                "Eliminar"
                                :
                                ""


                }
            </ModalHeader>
            {
                modalInfo.action === "add-worker" ?
                    <AddWorker
                        onSave={(wk: userType) => {
                            addWorker(user, wk,
                                () => {
                                    // On Success
                                    let wks = workers;
                                    wks.push(wk);
                                    setWorkers(wks);
                                    closeModal();
                                }, () => {
                                    // On Error
                                    closeModal();
                                });
                        }}
                        onCancel={() => {
                            closeModal();
                        }}
                    />
                    :
                    modalInfo.action === "edit-worker" ?
                        <EditWorker
                            worker={workerSelected}
                            onSave={(wk: userType) => {
                                editWorker(user, wk,
                                    () => {
                                        // On Success
                                        let wks = workers;
                                        wks[wks.indexOf(workerSelected)] = wk;
                                        setWorkers(wks);
                                        closeModal();
                                    }, () => {
                                        // On Error
                                        closeModal();
                                    });
                            }}
                            onCancel={() => {
                                closeModal();
                            }}
                        />
                        :
                        modalInfo.action === "delete-worker" ?
                            <DeleteWorker
                                onDelete={() => {
                                    deleteWorker(user, workerSelected,
                                        () => {
                                            // On Success
                                            let wks = workers;
                                            let rem = wks.filter(elem => elem !== workerSelected);
                                            setWorkers(rem);
                                            closeModal();
                                        }, () => {
                                            // On Error
                                            closeModal();
                                        });
                                }}
                                onCancel={() => {
                                    closeModal();
                                }}
                            />
                            :
                            <></>

            }

        </Modal>
    }

    return <div className="users-page">
        <Header />

        <ModalRender />

        {userRol.includes("admin") ?
            <div className="content-users">
                <NativeTitle>Control de usuarios</NativeTitle>
                <Row className='admin-edit-profiles'>
                    <Row className='d-flex justify-content-end'>
                        <Col xs="2" sm="1" className='d-flex justify-content-center'>
                            <Button className='w-90 refresh-button' onClick={() => {
                                refreshWorkers(user, (works: usersType) => {
                                    setWorkers(works);
                                }, () => {

                                })
                            }}>
                                {RefreshIcon}
                            </Button>
                        </Col>
                        <Col xs="5" sm="3">
                            <Button color="primary" className='w-100' onClick={() => {
                                setModalInfo({ open: true, action: "add-worker" });
                            }}>Agregar</Button>
                        </Col>
                    </Row>
                    <Row className='admin-table-container'>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center'>Editar</th>
                                    <th className='text-center'>Eliminar</th>
                                    <th className='text-center'>Nombre</th>
                                    <th className='text-center'>Email</th>
                                    <th className='text-center'>Área</th>
                                    <th className='text-center'>Cargo</th>
                                    <th className='text-center'>Celular</th>
                                    <th className='text-center'>Rol</th>
                                </tr>
                            </thead>
                            <tbody>
                                {workers.length > 0 ?
                                    workers.map((elem, index) => {
                                        return <tr key={"admin-edit-profiles-" + index}>
                                            <td className='text-center' onClick={() => {
                                                //Edit button
                                                openModal("edit-worker");
                                                setWorkerSelected(elem);
                                            }}>{EditIcon}</td>
                                            <td className='text-center' onClick={() => {
                                                //Delete icon
                                                openModal("delete-worker");
                                                setWorkerSelected(elem);
                                            }}>{DeleteIcon}</td>
                                            <td>{elem.nombre}</td>
                                            <td>{elem.email}</td>
                                            <td>{elem.area}</td>
                                            <td>{elem.cargo}</td>
                                            <td>{elem.celular}</td>
                                            <td>{elem.rol}</td>
                                        </tr>
                                    })
                                    :
                                    <></>
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Row>
            </div>
            : <></>

        }
    </div>
}

export default Users;

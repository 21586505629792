import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Alert, Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import { CarruselCard, CreateAlertsURL, CreateEdicionesURL, CreateRankingsURL, GenerateErrorsReport, InputEditable, LoadProgress, Subtitle, Title } from '../components/comps';
import { carruselType, DeleteIcon, EditIcon, notaDestacadaType, notaDiaType, post, RefreshIcon, updateReturnType, userKeys, usersType, userType } from '../theme/variables';

import "./scss/Admin.scss";
import "../components/scss/comps.scss";
import { toast } from 'react-toastify';
import { AddWorker, Confirm, Delete, DeleteWorker, EdicionesForm, EditWorker, NotasForm, RankingsForm, UpdatePass } from '../components/AdminForms';
import { addEdiciones, addNota, addRankings, addWorker, confirmNota, deleteNota, deleteWorker, editWorker, refreshWorkers, updatePass } from '../functions/AdminFunctions';
import Calidad from '../components/Calidad';
import Header from '../components/Header';
import NativeTitle from '../components/Title';

let Admin = () => {
    const [redirect, setRedirect] = useState("");
    const [user, setUser] = useState({} as userType);
    const [userRol, setUserRol] = useState([] as string[]);
    const [modalInfo, setModalInfo] = useState({ open: false, action: "" as ("ediciones" | "rankings" | "notas" | "add-worker" | "edit-worker" | "delete-worker" | "edit-nota" | "delete-nota" | "confirm-nota" | "update-pass" | "") });

    const [notaSelected, setNotaSelected] = useState({} as notaDiaType);
    const [textToast, setTextToast] = useState(<></>);
    const [carruselElements, setCarruselElements] = useState([] as carruselType[]);

    useEffect(() => {
        let usr: userType = JSON.parse(localStorage.getItem("user-auth")!);

        if (usr) {
            setUser(usr);
            setUserRol(usr.rol.split("|"));
        } else {
            setRedirect("/");
        }

        carrusel("obtain");

    }, []);

    //Functions

    let closeModal = () => {
        setModalInfo({ open: false, action: "" });
    }

    let openModal = (action: "ediciones" | "rankings" | "notas" | "add-worker" | "edit-worker" | "delete-worker" | "") => {
        setModalInfo({ open: true, action: action });
    }

    let carrusel = (action: string) => {
        let obtainCarruselElements = () => {
            let form = new FormData();

            form.append("clave", "obtain-carrusel-elements");

            post("admin.php", form, {}).then(r => {
                let res = r as { result: boolean, response: carruselType[] };

                if (res.result) {
                    setCarruselElements(res.response);
                } else {
                    toast.error("Ha ocurrido un error obteniendo los elementos del carrusel, intentalo de nuevo más tarde.");
                }
            }).catch(r => {
                toast.error("Ha ocurrido un error obteniendo los elementos del carrusel, intentalo de nuevo más tarde.");
            })
        }

        let editCarruselElements = () => {

        }
        let controlCarruselElements = () => {

        }

        switch (action) {
            case "obtain": obtainCarruselElements(); break;
            case "edit": editCarruselElements(); break;
            case "control": controlCarruselElements(); break
        }
    }

    //Components
    let ModalRender = () => {
        const [percentProgress, setPercentProgress] = useState(0);

        return <Modal isOpen={modalInfo.open} centered >

            <ModalHeader>

                <LoadProgress percent={percentProgress} />

                {modalInfo.action === "ediciones" ?
                    "Ediciones, informes especiales y separatas"
                    :
                    modalInfo.action === "rankings" ?
                        "Rankings económicos y finanzas"
                        :
                        modalInfo.action === "notas" ?
                            "Notas destacadas y del dia"
                            :
                            modalInfo.action === "add-worker" ?
                                "Agregar"
                                :
                                modalInfo.action === "edit-worker" ?
                                    "Editar"
                                    :
                                    modalInfo.action === "delete-worker" ?
                                        "Eliminar"
                                        :
                                        ""


                }
            </ModalHeader>
            {modalInfo.action === "ediciones" ?
                <EdicionesForm
                    onSave={(action: "ediciones" | "informes" | "separatas", values: FormData) => {
                        addEdiciones(user, action, values, (url: any) => {
                            //On successs
                            closeModal();

                            setTextToast(url);
                        }, () => {
                            //On error

                            closeModal();
                        }, (per: number) => {
                            setPercentProgress(per);
                        });
                    }}
                    onCancel={() => {
                        closeModal();
                    }} />
                :
                modalInfo.action === "rankings" ?
                    <RankingsForm
                        onSave={(action: "empresas" | "financiero", values: FormData) => {
                            addRankings(user, action, values, (url: any) => {
                                //On successs
                                closeModal();

                                setTextToast(url);
                            }, () => {
                                //On error

                                closeModal();
                            }, (per: number) => {
                                setPercentProgress(per);
                            });
                        }}
                        onCancel={() => {
                            closeModal();
                        }} />
                    :
                    modalInfo.action === "notas" ?
                        <NotasForm
                            onSave={(action: "nota-destacada" | "nota-dia", values: FormData) => {
                                addNota(user, action, values, (url: any) => {
                                    //On Success
                                    closeModal();

                                    setTextToast(url);
                                }, () => {
                                    //On Error
                                    closeModal();
                                }, (percent: number) => {
                                    setPercentProgress(percent);
                                });
                            }}
                            onCancel={() => {
                                closeModal();
                            }} />

                        :
                        modalInfo.action === "edit-nota" ?
                            <NotasForm
                                values={notaSelected}
                                onSave={(action: "nota-destacada" | "nota-dia", values: FormData) => {
                                    addNota(user, action, values, (url: any) => {
                                        //On Success
                                        closeModal();

                                        setTextToast(url);
                                    }, () => {
                                        //On Error
                                        closeModal();
                                    }, (percent: number) => {
                                        setPercentProgress(percent);
                                    });
                                }}
                                onCancel={() => {
                                    closeModal();
                                }} />
                            :
                            modalInfo.action === "confirm-nota" ?
                                <Confirm onCancel={() => {
                                    closeModal();
                                }} onConfirm={() => {
                                    confirmNota(user, notaSelected, () => {
                                        //On sucesss
                                        closeModal();
                                    }, () => {
                                        //On error
                                        closeModal();
                                    })
                                }} />
                                :
                                modalInfo.action === "delete-nota" ?
                                    <Delete onCancel={() => {
                                        closeModal();
                                    }} onDelete={() => {
                                        deleteNota(user, notaSelected, () => {
                                            //On sucesss
                                            closeModal();
                                        }, () => {
                                            //On error
                                            closeModal();
                                        })
                                    }} />
                                    :
                                    modalInfo.action === "update-pass" ?
                                        <UpdatePass onCancel={() => {
                                            closeModal();
                                        }} onConfirm={(ant: string, nuev: string) => {
                                            updatePass(user, ant, nuev, () => {
                                                //On sucesss
                                                closeModal();
                                            }, () => {
                                                //On error
                                                closeModal();
                                            })
                                        }} />
                                        :
                                        <></>

            }

        </Modal>
    }

    return <div className='admin-page add-content-page w-100 d-flex flex-column justify-content-start'>
        {redirect !== "" ? <Navigate to={redirect} /> : <></>}

        <Header />

        <ModalRender />

        <div className='admin-container d-flex flex-column justify-content-center align-items-center'>

            {/**    CARRUSEL CONRTOL     */}

            <br />

            <Title text='Agregar contenido' />

            <NativeTitle className='w-100 align-items-start px-4'>Carrusel</NativeTitle>
            <div className='carrusel-elements mt-0' >
                {carruselElements.length > 0 ?
                    carruselElements.map((element, index) => {
                        return <CarruselCard carrusel={element} user={user} onEdit={(newValues: carruselType) => {
                            let ejCarrusel = JSON.parse(JSON.stringify(carruselElements));
                            ejCarrusel[index] = newValues;

                            setCarruselElements(ejCarrusel);
                        }} />

                    })
                    :
                    <Label className='form-label w-100 text-center'>Cargando elementos del carrusel</Label>
                }
            </div>

            {/*     BOTONES DE FORMULARIOS      */}

            <br />

            <NativeTitle className='w-100 align-items-start px-4'>Revista y Notas</NativeTitle>

            <div className='admin-forms-buttons mt-0' >
                {userRol.includes("admin") ?
                    <>
                        <Button color='primary' onClick={() => {
                            setModalInfo({ open: true, action: "ediciones" });
                        }}>
                            Ediciones, informes especiales y separatas
                        </Button>
                        <Button color='primary' onClick={() => {
                            setModalInfo({ open: true, action: "rankings" });
                        }}>
                            Rankings económicos y finanzas
                        </Button>
                        <Button color='primary' onClick={() => {
                            setModalInfo({ open: true, action: "notas" });
                        }}>
                            Notas destacadas y del día
                        </Button>
                    </>
                    :
                    <>
                        {userRol.includes("ediciones") ?
                            <Button color="primary" onClick={() => {
                                setModalInfo({ open: true, action: "ediciones" });
                            }}>
                                Ediciones, informes especiales y separatas
                            </Button>
                            :
                            <></>
                        }
                        {userRol.includes("rankings") ?
                            <Button color="primary" onClick={() => {
                                setModalInfo({ open: true, action: "rankings" });
                            }}>
                                Rankings económicos y finanzas
                            </Button>
                            :
                            <></>
                        }
                        {userRol.includes("notas") ?
                            <Button color="primary" onClick={() => {
                                setModalInfo({ open: true, action: "notas" });
                            }}>
                                Notas destacadas y del día
                            </Button>
                            :
                            <></>
                        }

                    </>
                }
            </div>

            {/**        TOAST URL  PRESSENT      */}

            {textToast}

            <hr className='w-100' />

            {/***       CONTROL DE CALIDAD       */}
            {userRol.includes("calidad") || userRol.includes("admin") ?

                <Calidad user={user} />

                :

                <></>
            }

        </div>
    </div>
}
export default Admin;